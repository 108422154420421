<template>
	<Dialog
		titulo="Liberar Box para Pré Produção"
		subtitulo="Tenha certeza que todos os produtos já foram faturados"
		btn_acao="Liberar Box"
		btn_cancelar="Cancelar"
		:btn_acao_extra_disable="false"
		@acao="openConfirmDialog = true"
		:loading="loading"
		@close="$emit('update:close', false);">

		<v-container fluid grid-list-lg class="pa-0">
			<v-flex xs12>
				<v-data-table
					:headers="headers"
					:items="items"
					:loading="loading"
					:no-data-text="loading ? 'Carregando...' : 'Produtos não encontrados para essa Box'"
					:rows-per-page-items="[{text: 'Todos', value: -1}]"
					rows-per-page-text="Itens por página" hide-actions>
					<template v-slot:items="props">
						<tr class="custom-table">
							<td class="text-xs-left pb-3">
								{{ props.item.productName }}
								<!-- ICON PBM -->
								<v-tooltip top v-if="props.item.isPBM" >
									<v-chip class="justify-center" color="blue" style="max-width: 22px; max-height: 22px;" slot="activator" dark
										small><strong>%</strong></v-chip>
									<span>PBM</span>
								</v-tooltip>
								<br/>
								<span>{{ props.item.productDescription }}</span>

							</td>
							<td class="pt-3 pb-3">
								<v-text-field
									:disabled="isDisabled(props.item, 'tagId')"
									v-model="props.item.tagId"
									single-line
									solo
									@keydown.enter.prevent="getTagInfo(props.index)"
									v-validate="{ required: (!props.item.batch || !props.item.expirationDate), regex: /(^\d{8}$)|(^[vV]\d{13}$)/ }"
									:data-vv-name="`etiqueta_${props.index+1}`"
									:error-messages="
										errors.collect(`etiqueta_${props.index+1}`)
									"/>
							</td>
							<td class="pt-3 pb-3">
								<v-text-field
									:disabled="isDisabled(props.item, 'batch')"
									v-model="props.item.batch"
									single-line
									solo
									v-validate="{ required: (props.item.isRequired) }"
									@keydown.enter.prevent="submit"
									:data-vv-name="`lote_${props.index+1}`"
									:error-messages="
										errors.collect(`lote_${props.index+1}`)
									"/>
							</td>
							<td class="pt-3 pb-3">
								<TextDateField
									:disabled="isDisabled(props.item, 'expirationDate')"
									v-model="props.item.expirationDate"
									:label="`validade_${props.index+1}`"
									placeholder="dd/mm/yyyy"
									single-line
									solo
									v-validate="{ required: (props.item.isRequired) }"
									:data-vv-name="`validade_${props.index+1}`"
									:error-messages="
										errors.collect(`validade_${props.index+1}`)
									"/>
							</td>
							<td class="pt-3 pb-3">
								<v-text-field
									:disabled="isDisabled(props.item, 'price')"
									:label="`valor_${props.index+1}`"
									v-model="props.item.price"
									v-money="true"
									single-line
									solo
									v-validate="{ required: true }"
									:rules="getPriceRules(props.index)"
									:data-vv-name="`valor_${props.index+1}`"
									:error-messages="
										errors.collect(`valor_${props.index+1}`)
									"/>
							</td>
							<td class="pl-0 pr-0 pt-3 pb-3">
								<div style="display:flex" class="justify-end">
									<v-tooltip top v-if="props.item.showChangeBoxActionButtons">
										<v-btn small icon @click.stop="openExtraActionDialog(props.index, extraActions.swap)" slot="activator">
											<v-icon>swap_horiz</v-icon>
										</v-btn>
										<span>Alterar medicamento da box</span>
									</v-tooltip>
									<v-tooltip top v-if="props.item.showChangeBoxActionButtons">
										<v-btn small icon @click.stop="openExtraActionDialog(props.index, extraActions.delete)" slot="activator">
											<v-icon>remove_circle_outline</v-icon>
										</v-btn>
										<span>Retirar medicamento da box</span>
									</v-tooltip>
									<v-tooltip top v-if="(isDisabled(props.item, 'tagId')|| isDisabled(props.item, 'batch') || isDisabled(props.item, 'expirationDate')) && !loading">
										<v-btn small icon @click.stop="removeMedicine(props.index)" slot="activator">
											<v-icon>edit</v-icon>
										</v-btn>
										<span>Editar lote</span>
									</v-tooltip>
								</div>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-flex>
			<v-flex class="px-3" v-if="showStockSeparatedConfirmation">
				<v-checkbox
					v-model="stockSeparatedConfirmed"
					label="Confirmo que separei o estoque do cliente"
					hide-details
					v-validate="{ required: true }"
					data-vv-name="Confirmo que separei o estoque do cliente"
					:error-messages="errors.collect('Confirmo que separei o estoque do cliente')" />
			</v-flex>
			<!-- </v-layout> -->
		</v-container>

		<v-dialog persistent scrollable v-model="openSwapMedicine" transition="fade" max-width="920px">
			<transition>
				<SwapMedicineDialog v-if="openSwapMedicine" :boxId="boxId" :product="selectedProductToAction" :tagInfo="selectedTag" @close="() => closeExtraActionDialog(extraActions.swap)"
					@update="updateInfo"/>
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="openDeleteProduct" transition="fade" max-width="920px">
			<transition>
				<DeleteProductFromBoxDialog v-if="openDeleteProduct" :boxId="boxId" :product="selectedProductToAction" @close="() => closeExtraActionDialog(extraActions.delete)" @update="updateInfo"/>
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="openConfirmDialog" transition="fade" max-width="920px">
			<transition>
				<ConfirmManualInvoicingDialog v-if="openConfirmDialog" :boxId="boxId" @confirm="save" @close="openConfirmDialog = false"/>
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { EtiquetaPresenter } from '@Presenter/etiqueta-presenter';
import unleash from '@Util/unleash';
import { featureFlags } from '@Consts/feature-flags';
import SwapMedicineDialog from './swap-medicine-dialog.vue';
import DeleteProductFromBoxDialog from './delete-product-dialog.vue';
import ConfirmManualInvoicingDialog from './confirm-dialog.vue';

export default {
	name: 'TraceabilityDialog',
	$_veeValidate: { validator: 'new' },
	components: { SwapMedicineDialog, DeleteProductFromBoxDialog, ConfirmManualInvoicingDialog },
	props: {
		boxId: {
			required: true,
			type: Number
		}
	},
	data: () => ({
		stockItems: [],
		items: [],
		selectedProductToAction: null,
		selectedTag: null,
		loading: false,
		extraActions: {
			swap: 'SWAP',
			delete: 'DELETE'
		},
		showStockSeparatedConfirmation: false,
		stockSeparatedConfirmed: false,
		openSwapMedicine: false,
		openDeleteProduct: false,
		openConfirmDialog: false,
		headers: [
			{
				text: 'Produto', value: 'produto', sortable: false, width: '200px'
			},
			{
				text: 'Etiqueta', value: 'etiqueta', sortable: false, width: '200px'
			},
			{
				text: 'Lote', value: 'lote', sortable: false, width: '200px'
			},
			{
				text: 'Validade', value: 'validade', sortable: false, width: '200px'
			},
			{
				text: 'Valor faturado', value: 'validade', sortable: false, width: '150px'
			}
		]
	}),
	async created() {
		unleash.on(
			'update',
			() => {
				this.updateFeatureFlag(!!unleash.isEnabled(featureFlags.change_box_medicines_manual_invoicing));
			}
		);
		await this.getTraceabilityInfo();
	},
	computed: {
		user_id() {
			return this.$store.getters.user.id;
		}
	},
	methods: {
		close() {
			this.$emit('update:close', false);
		},
		updateFeatureFlag(value) {
			this.items = this.items.map(item => ({
				...item,
				showChangeBoxActionButtons: !item.processedAutomatically && value
			}));
		},
		async updateInfo() {
			await this.getTraceabilityInfo(true);
		},
		async getTraceabilityInfo(copyCurrentInfo = false) {
			this.loading = true;
			try {
				const response = await BoxPresenter.manualPickingV2(this.boxId);
				this.stockItems = response.items;
				this.showStockSeparatedConfirmation = response.patientHadStock;
				const info = this.stockItems.map(item => {
					const baseItem = {
						id: item.id,
						productId: item.product.id,
						expirationDate: this.$functions.FormatDateFromRequest(item.expirationDate),
						batch: item.batch,
						ean: '',
						productName: `${item.product.name}`,
						productDescription: `${item.product.presentation} - ${item.product.brand}`,
						isPBM: item.product.isPBM,
						tagId: item.tagId,
						isRequired: item.product.isControlled || !item.product.isOutOfBox,
						userId: (item.tagId || (item.batch && item.expirationDate)) ? item.userId : this.user_id,
						price: item.price,
						showChangeBoxActionButtons: !item.processedAutomatically && !!unleash.isEnabled(featureFlags.change_box_medicines_manual_invoicing)
					};
					if (copyCurrentInfo) {
						const previousInfo = this.items.find(i => i.id === item.id);
						return previousInfo ? { ...previousInfo } : { ...baseItem };
					}
					return { ...baseItem };
				});
				this.items = info;
				this.loading = false;
			} catch (e) {
				this.$emit('error');
			} finally {
				this.loading = false;
			}
		},
		async getTagInfo(index) {
			const searchTag = this.items[index] && this.items[index].tagId;
			if (searchTag) {
				try {
					this.loading = true;
					const tagInfo = await EtiquetaPresenter.get(searchTag);

					if (this.items[index].productId !== tagInfo.product.id) {
						this.selectedProductToAction = this.stockItems[index].product;
						this.selectedTag = tagInfo;
						this.openSwapMedicine = true;
						return;
					}
					this.items[index].batch = tagInfo?.batch || null;
					this.items[index].expirationDate = tagInfo?.expirationDate ? this.$functions.FormatDateFromRequest(tagInfo?.expirationDate) : null;
				} catch (e) {
					this.items[index].batch = null;
					this.items[index].expirationDate = null;
					this.$store.dispatch('SHOW_SNACKBAR', {
						message: `Não foi possível buscar as informações da etiqueta: ${e.message}`,
						color: 'warning'
					});
				} finally {
					this.loading = false;
				}
			}
		},
		async save() {
			const formIsValidated = await this.$validator.validateAll();

			if (!formIsValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário inválido',
					color: 'error'
				});
				return;
			}

			if (!this.isValidTags()) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Etiquetas não podem ser iguais',
					color: 'error'
				});
				return;
			}

			if (this.showStockSeparatedConfirmation && !this.stockSeparatedConfirmed) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Confirme que separou o estoque do cliente',
					color: 'error'
				});
				return;
			}

			try {
				this.loading = true;
				await BoxPresenter.manualPicking(this.boxId, {
					user_id: this.user_id,
					items: this.items.map(i => ({
						...i,
						product_id: i.productId,
						batch: i.batch,
						tag_id: i.tagId,
						expiration_date: this.$functions.FormatDateToRequest(i.expirationDate),
						user_id: i.userId,
						price: Number(this.$functions.onlyNumber(i.price))
					})),
					stock_separated: this.stockSeparatedConfirmed
				});

				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Box liberada para Pré Produção',
					color: 'success'
				});

				this.$emit('onSuccess');
				this.$emit('update:close', false);
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao liberar Box',
					color: 'error'
				});
			} finally {
				this.loading = false;
			}
		},
		isValidTags() {
			const tags = this.items.filter(i => i.tagId).reduce((acc, item) => {
				acc[item.tagId] = (acc[item.tagId] || 0) + 1;
				return acc;
			}, {});
			return Object.keys(tags).every(key => tags[key] === 1);
		},
		openExtraActionDialog(index, action) {
			if (action === this.extraActions.swap)
				this.openSwapMedicine = true;
			else
				this.openDeleteProduct = true;

			this.selectedProductToAction = this.stockItems[index].product;
		},
		closeExtraActionDialog(action) {
			if (action === this.extraActions.swap) {
				this.selectedTag = null;
				this.openSwapMedicine = false;
			} else
				this.openDeleteProduct = false;
			this.selectedProductToAction = null;
		},
		removeMedicine(index) {
			this.stockItems[index].expirationDate = null;
			this.stockItems[index].tagId = null;
			this.stockItems[index].batch = null;
			this.stockItems[index].price = null;
			this.stockItems[index].userId = this.user_id;
			this.items[index].tagId = null;
			this.items[index].expirationDate = null;
			this.items[index].batch = null;
			this.items[index].price = null;
			this.items[index].userId = this.user_id;
		},
		getPriceRules(index) {
			const isRequired = !!((this.items[index].tagId || this.items[index].batch) && this.items[index].batch?.toLowerCase() !== 'sus' && this.items[index].batch?.toLowerCase() !== 'manipulado');

			const rules = [];
			if (isRequired) {
				rules.push(v => !!v || `Valor ${index + 1} é obrigatório`);
				rules.push(v => {
					if (typeof v === 'number')
						return v > 0 || `Valor ${index + 1} deve ser maior que 0`;
					const numericValue = v ? parseFloat(v.replace(/[^\d.-]/g, '')) : 0;
					return numericValue > 0 || `Valor ${index + 1} deve ser maior que 0`;
				});
			}

			return rules;
		},
		isDisabled(currentItem, field) {
			if (this.loading)
				return true;
			const item = this.stockItems.find(i => i.id === currentItem.id);
			return !!(item && item[field]);
		}
	}
};
</script>

<style lang="scss" scoped>

  ::v-deep .v-text-field.v-text-field--solo .v-input__control{
	padding-top: 20px!important
  }
</style>
