<template>
	<div class="assinaturas-wrapper px-md-5">
		<!-- Assinaturas Header -->
		<Header @nova-assinatura="novaAssinatura" @gerar-excel="gerarExcel" @busca="onBusca" @filtro="onFiltro" @casas-repouso-updated="onCasasRepouso" />

		<!-- Conteúdo -->
		<v-card>
			<v-data-table
				:headers="headers"
				:items="assinaturas"
				:loading="carregando"
				:no-data-text="(carregando) ? 'Carregando...' : (erro) ? 'Erro ao carregar, tente novamente' : 'Nenhuma assinatura'"
				no-results-text="Nenhum resultado encontrado"
				:rows-per-page-items="[15, 30, 45, 90, {text: 'Todos', value: -1}]"
				:pagination.sync="pagination"
				:total-items="totalItems"
				rows-per-page-text="Itens por página">
				<template slot="items" slot-scope="props">
					<tr @click="selecionaAssinatura(props.item.id)">
						<td class="text-xs-center">{{ props.item.id }}</td>
						<td class="py-2" style="white-space: nowrap">
							<v-avatar size="24" class="mr-2">
								<span class="white--text">
									{{ props.item.paciente.nome.charAt(0) }}
								</span>
							</v-avatar>
							<GlobalTags
								v-for="(global_tag, index) in props.item.paciente.global_tags"
								:key="index"
								:global_tag="global_tag"/>
							{{ props.item.paciente.nome }}
							<BoxExpress :express="props.item.express"/>
							<FirstBox v-if="!props.item.express && props.item.primeira_box" />
						</td>
						<td>
							{{ props.item.inicio_proxima_box | formatDate('DD/MM/YYYY [(]dddd[)]') }}
						</td>
						<td>
							{{ (({id, nome}) => nome ? `${id||''}${id ? ' - ' : ''}${nome}` : '-')(props.item.paciente.casa_repouso || {}) }}
						</td>
						<td class="text-xs-center">{{ props.item.__meta__.medicamentos_count }}</td>
						<td class="text-xs-right pl-0">
							<v-chip v-if="props.item.status_ultima_box" small disabled class="mx-0 white--text info">
								{{ props.item.status_ultima_box | statusBox }}
							</v-chip>
							<span v-else>-</span>
						</td>
						<td>{{ props.item.id_ultima_box }}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog persistent v-model="nova_assinatura" fullscreen hide-overlay scrollable
			transition="fade">
			<NovaAssinatura v-if="nova_assinatura" type="b2b" :close.sync="nova_assinatura" @assinatura-salva="assinaturaSalva" />
		</v-dialog>
	</div>
</template>

<script>
import { isEqual, debounce } from 'lodash';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import NovaAssinatura from '@Componentes/assinatura/nova-assinatura';
import BoxExpress from '@Componentes/box-express';
import FirstBox from '@Componentes/first-box';
import GlobalTags from '@Componentes/global-tags';

import Header from './header';

export default {
	name: 'Assinaturas',
	components: {
		Header,
		NovaAssinatura,
		BoxExpress,
		FirstBox,
		GlobalTags
	},
	data: () => ({
		carregando: true,
		erro: false,
		busca: null,
		nova_assinatura: false,
		headers: [
			{
				text: 'ID Assinatura', value: 'id', align: 'center', width: '10'
			},
			{ text: 'Paciente', value: 'paciente' },
			{ text: 'Próxima box', value: 'inicio_proxima_box' },
			{ text: 'Casa de repouso', value: 'casa_repouso' },
			{
				text: 'Quantidade de medicamentos', value: '', align: 'center', sortable: false
			},
			{ text: 'Status da Box', value: 'status_ultima_box', align: 'center' },
			{
				text: 'ID Box', value: 'id_ultima_box', align: 'center', width: '10'
			}
		],
		assinaturas: [],
		totalItems: 0,
		index_props: {
			filtro: 'ativas',
			casasRepouso: []
		},
		pagination: {
			sortBy: 'id',
			descending: true
		}
	}),
	watch: {
		index_props: {
			async handler(val, oldVal) {
				if (!isEqual(val, oldVal))
					await this.buscaAssinaturas();
			},
			deep: true
		},
		pagination: {
			async handler({
				page, rowsPerPage, sortBy, descending
			}, oldVal) {
				if (!sortBy) { // skipping 'null state' of sortBy (3rd click in same column)
					oldVal.descending = !oldVal.descending;
					this.pagination = oldVal;
					return;
				}
				this.index_props = {
					...this.index_props,
					order: descending ? 'desc' : 'asc',
					page,
					rowsPerPage,
					sortBy
				};
			},
			deep: true
		}
	},
	methods: {
		isPrimeiraBox() {},
		onFiltro(filtro) {
			this.index_props = { ...this.index_props, filtro };
		},
		onBusca: debounce(function (search) {
			this.index_props = { ...this.index_props, search };
		}, 750),
		onCasasRepouso(casasRepouso) {
			this.index_props = { ...this.index_props, casasRepouso };
		},
		gerarExcel() {
			import('./excel-assinaturas').then(Module => {
				Module.default(this.assinaturas);
			});
		},
		buscaAssinaturas() {
			this.assinaturas = [];
			this.carregando = true;
			AssinaturaPresenter.list(this.index_props)
				.then(response => {
					this.assinaturas = response.items;
					this.totalItems = response.total;
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		selecionaAssinatura(id) {
			this.$router.push({ name: 'Assinatura', params: { id } });
		},
		novaAssinatura() {
			this.nova_assinatura = true;
		},
		assinaturaSalva(assinatura) {
			this.nova_assinatura = false;
			this.assinaturas.push(this.$lodash.cloneDeep(assinatura));
		}
	}
};
</script>

<style lang="scss" scoped>
.v-card {
  overflow: hidden;

  ::v-deep .v-table {
    thead,
    tbody {
      tr {
        th,
        td {
          &:first-child,
          &:last-child {
            width: 1%;
            white-space: nowrap;
          }
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}

::v-deep td.text-xs-right {
  .v-menu__activator {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
