<template>
	<v-container fluid grid-list-md class="pa-0">
		<v-layout wrap>
			<v-flex md6 xs12>
				<p>
					<strong>Unidade de processamento do pedido: </strong>
					Qual filial fará o processamento e entrega do pedido pontual. Essa informação pode sobrepor a unidade original uma assinatura.
				</p>
			</v-flex>
			<v-flex md6 xs12>
				<v-select
					v-model="extra.orderProcessingUnitId"
					:items="units"
					hide-selected
					solo
					background-color="white"/>
			</v-flex>

			<v-flex xs12 class="text-centerl">
				<small class="text-center mx-auto text-small">Automático = O pedido será processado de acordo com o endereço de entrega (não assinantes) ou a unidade de cadastro da assinatura (assinantes).</small>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { getAllCompanyUnits } from '@Consts/unidades';

export default {
	props: {
		extra: {
			required: true,
			type: Object
		}
	},
	computed: {
		units() {
			const units = getAllCompanyUnits().map(item => ({
				value: item.id,
				text: `${item.name} (${item.abbr})`
			}));
			units.push({ value: null, text: 'Automático' });
			return units;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
