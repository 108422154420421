import { SettingsPresenter } from '@Presenter/v2/settings-presenter';

export default {
	props: {
		quotation: {
			type: Object
		},
		isNewQuotation: {
			type: Boolean
		}
	},
	computed: {
		getFinalPriceQuotationItems() {
			const items = this.quotation.items || [];
			return items.reduce(
				(acc, item) => acc + item.values.totalFinalPrice,
				0
			);
		}
	},
	watch: {
		'quotation.items': async function () {
			const shippingFee = await SettingsPresenter.getShippingFee();

			const total = this.getFinalPriceQuotationItems;
			if (total > shippingFee.limit)
				this.quotation.shippingPrice = 0;
			else {
				const oldShippingPrice = this.$functions.onlyNumber(this.quotation.shippingPrice, true);
				if (oldShippingPrice === 0)
					this.quotation.shippingPrice = shippingFee.value;
			}
		}
	}

};
