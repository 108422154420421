const statusEnum = {
	PAID: 'PAID',
	PARTIAL_PAID: 'PARTIAL_PAID',
	REFUND: 'REFUND',
	CANCELED: 'CANCELED',
	PARTIAL_REFUND: 'PARTIAL_REFUND',
	EXPIRED: 'EXPIRED',
	CONTESTED: 'CONTESTED',
	PROTESTED: 'PROTESTED',
	OVERDUE: 'OVERDUE',
	PENDING: 'PENDING',
	WAITING_REFUND: 'WAITING_REFUND'
};

const statusLabel = {
	[statusEnum.PAID]: 'Paga',
	[statusEnum.PARTIAL_PAID]: 'Parcialmente paga',
	[statusEnum.PARTIAL_REFUND]: 'Parcialmente reembolsada',
	[statusEnum.REFUND]: 'Reembolsada',
	[statusEnum.CANCELED]: 'Cancelada',
	[statusEnum.EXPIRED]: 'Expirada',
	[statusEnum.CONTESTED]: 'Contestada',
	[statusEnum.PROTESTED]: 'Em protesto',
	[statusEnum.OVERDUE]: 'Vencida',
	[statusEnum.PENDING]: 'Aguardando pagamento',
	[statusEnum.WAITING_REFUND]: 'Aguardando reembolso'
};

const statusChargeLabel = {
	paid: 'Pago',
	partial_paid: 'Parcialmente paga',
	pending: 'Pendente',
	canceled: 'Cancelado',
	refund: 'Reembolsado',
	partial_refund: 'Reembolso Parcial',
	expired: 'Expirado',
	overdue: 'Vencido',
	failed: 'Falha no Pagamento'
};

const statusClass = {
	[statusEnum.PAID]: 'color: #342B1D;background-color: #42D786',
	[statusEnum.REFUND]: 'color: #fff;background-color: #8D8D8D',
	[statusEnum.PARTIAL_PAID]: 'color: #fff;background-color: #8D8D8D',
	[statusEnum.PARTIAL_REFUND]: 'color: #fff;background-color: #8D8D8D',
	[statusEnum.CANCELED]: 'color: #B51717;background-color: #FFDEDE',
	[statusEnum.EXPIRED]: 'color: #5B5B5B;background-color: #E0E0E0',
	[statusEnum.CONTESTED]: 'color: #fff;background-color: #E50000',
	[statusEnum.PROTESTED]: 'color: #FF5C00;background-color: #FFD8C1',
	[statusEnum.OVERDUE]: 'color: #fff;background-color: #E50000',
	[statusEnum.PENDING]: 'color: #fff;background-color: #409EFF',
	[statusEnum.WAITING_REFUND]: 'color: #fff;background-color: #8D8D8D'
};

const typesEnum = {
	BOX: 'box',
	JORNADA_DIGITAL: 'jornada-digital',
	PONTUAL: 'pontual',
	REEMISSAO_DE_COBRANCA: 'reemissao-de-cobranca',
	COBRANCA_DE_ESTOQUE: 'cobranca-de-estoque',
	SOLICITACAO_COMPLEMENTAR: 'solicitacao-complementar'
};

const typesLabel = {
	[typesEnum.BOX]: 'Box',
	[typesEnum.JORNADA_DIGITAL]: 'Jornada Digital',
	[typesEnum.PONTUAL]: 'Pontual',
	[typesEnum.REEMISSAO_DE_COBRANCA]: 'Reemissão de cobrança',
	[typesEnum.COBRANCA_DE_ESTOQUE]: 'Cobrança de estoque',
	[typesEnum.SOLICITACAO_COMPLEMENTAR]: 'Solicitação Complementar'
};

const documentTypeEnum = {
	CPF: 'cpf',
	CNPJ: 'cnpj'
};

const paymentsMethod = {
	creditCard: 'Credit Card',
	pix: 'Pix',
	bankSlip: 'Bank Slip'
};

const enumGateways = {
	IUGU: 'iugu',
	FARME_FINANCE: 'farme-finance'
};

function getInvoiceStatusAttributes(status) {
	return {
		class: statusClass[status],
		label: statusLabel[status]
	};
}

function getInvoiceTypeAttributes(type) {
	return {
		label: typesLabel[type]
	};
}

export {
	statusChargeLabel, statusEnum, statusLabel, statusClass, typesEnum, typesLabel, documentTypeEnum, paymentsMethod, enumGateways, getInvoiceStatusAttributes, getInvoiceTypeAttributes
};
