<template>
	<Dialog titulo="Alterar Ciclo" @close="close" btn_acao="Salvar" btn_cancelar="Cancelar" @acao="change"
		@cancelar="close">
		<v-container fluid grid-list-md>
			<v-layout wrap>
				<v-flex xs12 md6>
					<TextDateField v-model="cycleStartDate" box hideDetails flat
						label="Início" placeholder="dd/mm/yyyy" color="green_strong" />
				</v-flex>
				<v-flex xs12 md6>
					<v-select
						:hide-details="true"
						:items="cycleOptions"
						item-text="text"
						item-value="days"
						label="Qual o tamanho do ciclo?"
						outline
						v-model="days" />
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog persistent v-model="confirmationDialog" scrollable transition="fade" max-width="400px">
			<transition>
				<Dialog v-if="confirmationDialog" titulo="Alterar ciclo" subtitulo="Tem certeza que deseja alterar os dados do ciclo?"
					btn_acao="Sim" btn_acao_class="success" btn_cancelar="Cancelar" @acao="send"
					@close="confirmationDialog = false">
					<v-container fluid grid-list-lg>
						Ao confirmar, todos as alterações realizadas para esse ciclo até o momento serão perdidas.
					</v-container>
				</Dialog>
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
export default {
	name: 'CycleDialog',
	props: {
		startDate: {
			type: String,
			required: true
		},
		boxDays: {
			type: Number,
			required: false,
			default: 30
		}
	},
	data: () => ({
		confirmationDialog: false,
		cycleOptions: [{
			days: 30,
			text: '30 dias (1 ciclo)'
		},
		{
			days: 60,
			text: '60 dias (2 ciclos)'
		}],
		days: 30,
		cycleStartDate: null,
		enviando: true
	}),
	created() {
		this.cycleStartDate = this.startDate;
		this.days = this.boxDays;
	},
	methods: {
		close() {
			this.$emit('close');
		},
		change() {
			this.confirmationDialog = true;
		},
		send() {
			this.$emit('dataCycleChanged', {
				startDate: this.cycleStartDate,
				days: this.days
			});
			this.confirmationDialog = false;
			this.close();
		}
	}
};
</script>

<style lang="scss" scoped>
