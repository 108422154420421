<template>
	<v-layout align-center class="p-0" v-if="!isCancelled">
		<v-flex>
			<CancelPontualQuotationDialog v-if="!isDone" @handleCancel="onCancelPontualQuotation"/>
		</v-flex>
		<v-flex class="display-flex justify-end">
			<ApprovePontualQuotationDialog
				v-if="canApprove"
				:hasControlled="hasControlled"
				:hasPrescriptions="hasPrescriptions"
				@handleApprove="onApprovePontualQuotation" />
		</v-flex>
	</v-layout >
</template>

<script>
import { PontualQuotationUtil } from '@Util/pontual-quotation';
import { permissions } from '@Consts/permissoes';
import CancelPontualQuotationDialog from './cancel-pontual-quotation.vue';
import ApprovePontualQuotationDialog from './approve-pontual-quotation.vue';

export default {
	components: {
		CancelPontualQuotationDialog,
		ApprovePontualQuotationDialog
	},
	props: {
		hasControlled: {
			required: false,
			type: Boolean,
			default: false
		},
		hasPrescriptions: {
			required: true,
			type: Boolean
		},
		quotationStatus: {
			required: true,
			type: String
		},
		quotationBusinessType: {
			required: true,
			type: String
		}
	},
	methods: {
		onCancelPontualQuotation() {
			this.$emit('handleCancel');
		},
		onApprovePontualQuotation() {
			this.$emit('handleApprove');
		}
	},
	computed: {
		isCancelled() {
			return PontualQuotationUtil.isCanceled(this.quotationStatus);
		},
		isDraft() {
			return PontualQuotationUtil.isDraft(this.quotationStatus);
		},
		isDone() {
			return PontualQuotationUtil.isDone(this.quotationStatus);
		},
		isCanceled() {
			return PontualQuotationUtil.isCanceled(this.quotationStatus);
		},
		isPaid() {
			return PontualQuotationUtil.isPaid(this.quotationStatus);
		},
		isB2C() {
			return PontualQuotationUtil.isB2C(this.quotationBusinessType);
		},
		isUserAllowedToApprovePontualQuotation() {
			return this.$store.getters.isPermissionEnabled(permissions.approve_pontual_quotation);
		},
		canApprove() {
			if (this.isDone)
				return false;

			if (this.isCanceled)
				return false;

			if (this.isB2C && this.isPaid && this.hasControlled && this.hasPrescriptions && this.isUserAllowedToApprovePontualQuotation)
				return true;

			if (!this.isB2C) {
				if (!this.hasControlled || (this.hasControlled && this.hasPrescriptions && this.isUserAllowedToApprovePontualQuotation))
					return true;
			}

			return false;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
