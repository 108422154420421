2<template>
	<v-layout wrap>
		<v-flex xs12>

			<v-badge right overlap color="primary" style="width: 100%;">
				<template v-if="config.canChangeCustomer" v-slot:badge>
					<v-icon class="pointer" @click="$emit('resetCustomer')">close</v-icon>
				</template>
				<v-card elevation="0" class="pa-3" full-width>
					<v-card-text>
						<h5 class="title font-weight-bold mb-3">
							{{ customer.name }} - ID: {{ customer.externalId }}
						</h5>
						<ul class="ma-0 pa-0" style="list-style: none;">
							<li>
								<strong>CPF:</strong> {{ customer.documentNumber }}
							</li>
							<li>
								<strong>Data de nascimento:</strong> {{ getCustomerBirthdate }}
							</li>
							<li>
								<strong>Sexo:</strong> {{ getCustomerSex }}
							</li>
							<li>
								<strong>Assinatura:</strong> {{ getSubscriptionText }}
							</li>
						</ul>
					</v-card-text>
				</v-card>
			</v-badge>

		</v-flex>

		<v-flex xs12>
			<h3 class="body-1 font-weight-bold my-3">
				Cliente - Dados de contato
			</h3>
		</v-flex>

		<v-flex md6 xs12>
			<v-text-field
				v-model="customer.email"
				box
				:disabled="!config.canChangeEmail"
				label="E-mail *"
				data-vv-name="E-mail"
				:error-messages="errors.collect('E-mail')"
				background-color="white"
				v-validate="{ required: true }"  />
		</v-flex>

		<v-flex md6 xs12>
			<v-text-field
				v-model="customer.phone"
				box
				label="Telefone *"
				:mask="'(##) #####-####' || '(##) ####-####'"
				data-vv-name="Telefone"
				:error-messages="errors.collect('Telefone')"
				background-color="white"
				v-validate="{ required: true, telefone: true }"/>
		</v-flex>
	</v-layout>
</template>

<script>
import { sex } from '@Consts/sexo';
import { companyUnitAbbr } from '@Consts/unidades';

export default {
	name: 'PersonInfo',
	$_veeValidate: { validator: 'new' },
	props: {
		customer: {
			required: true,
			type: Object
		},
		extra: {
			required: false,
			type: Object,
			default: () => ({})
		},
		config: {
			required: true,
			type: Object
		}
	},
	computed: {
		getCustomerSex() {
			if (!this.customer.gender)
				return 'Não informado';

			return sex[this.customer.gender];
		},
		getCustomerBirthdate() {
			if (!this.customer.birthdate)
				return 'Não informado';
			return this.customer.birthdate;
		},
		getSubscriptionText() {
			if (this.extra.person && this.extra.person.subscription)
				return `ID: ${this.extra.person.subscription.id} / ${companyUnitAbbr[this.extra.person.subscription.companyUnitId]}`;
			return 'Não possui assinatura ativa';
		}
	},
	methods: {
		async validate() {
			const isValid = await this.$validator.validateAll();
			return !!isValid;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
