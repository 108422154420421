<template>
	<section>
		<div class="header">
			<h3>Medicamentos</h3>

			<div>
				<v-btn
					color="primary"
					:loading="isLoadingPrintQuotation"
					:disabled="isLoadingPrintQuotation"
					@click="$emit('printQuotation')">Imprimir orçamento
					<v-icon small class="ml-2">print</v-icon>
				</v-btn>

				<v-dialog
					persistent
					scrollable
					v-model="searchMedicineDialog"
					transition="fade">

					<template v-slot:activator="{ on }">
						<v-btn
							color="primary"
							class="px-5"
							v-on="on"
							@click.stop="searchMedicineDialog = true">Consultar
							<v-icon small class="ml-2">search</v-icon>
						</v-btn>
					</template>

					<SearchMedicineDialog
						v-if="searchMedicineDialog"
						:close.sync="searchMedicineDialog"
						:fluxo="'recorrencia'"
						:assinaturaId="subscription.id"/>
				</v-dialog>

				<v-dialog
					persistent
					scrollable
					v-model="addMedicineDialog"
					transition="fade">

					<template v-slot:activator="{ on }">
						<v-btn
							color="primary"
							class="px-4"
							v-on="on"
							@click.stop="addMedicineDialog = true">
							Adicionar
							<v-icon small class="ml-2">add</v-icon>
						</v-btn>
					</template>

					<AddMedicineDialog
						v-if="addMedicineDialog"
						fluxo="recorrencia"
						:assinaturaId="subscription.id"
						:orcamento_filial_id="subscription ? subscription.filial_id : null"
						:discount_percentage="getDiscountPercentage"
						:externalBilling="subscription ? Boolean(subscription.cobranca_externa) : false"
						:subscriptionMode="subscription.modalidade"
						@fechar-dialog="addMedicineDialog = false"
						@medicamento-salvo="addItem"/>
				</v-dialog>
			</div>
		</div>

		<MedicineTable
			:items="items"
			:isPrescriptionCycleVisible="false"
			:isPrescriptionReuseVisible="!extendedCycle"
			:initialDateToPause="initialDateToPause"
			:subscriptionMode="subscription.modalidade"
			@updatePrescriptionCycle="updatePrescriptionCycle"
			@pauseChanged="editPause"
			@deleteItem="deleteItem"
			@editItem="editItem" />
	</section>
</template>

<script>
import { RascunhoPresenter } from '@Presenter/alteracoes-presenter';
import { isBoxBeforePicking } from '@Util/functions';
import SearchMedicineDialog from '@Componentes/medicamento/dialog-medicamento/dialog-pesquisar-medicamento';
import moment from 'moment';
import AddMedicineDialog from '@Componentes/medicamento/dialog-medicamento/dialog-medicamento.vue';
import MedicineTable from './table.vue';

export default {
	name: 'DraftDialogMedicineSection',
	components: { MedicineTable, SearchMedicineDialog, AddMedicineDialog },
	props: {
		items: {
			required: false,
			type: Array,
			defualt: () => ([])
		},
		subscription: {
			required: true,
			type: Object
		},
		extendedCycle: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			addMedicineDialog: false,
			searchMedicineDialog: false,
			isLoadingPrintQuotation: false
		};
	},
	computed: {
		initialDateToPause() {
			if (!this.subscription)
				return null;

			let date;
			// verifica se box ja passou pelo picking
			const box = this.subscription.boxes[0];
			const antesPicking = box && isBoxBeforePicking(box.status);

			if (antesPicking)
				date = moment(box.inicio, 'YYYY-MM-DD');
			else
				date = moment(this.subscription.data_proxima_box, 'DD/MM/YYYY');

			return date;
		},
		getDiscountPercentage() {
			if (this.subscription && this.subscription.paciente && this.subscription.paciente.casa_repouso)
				return this.subscription.paciente.casa_repouso.porcentagem_desconto;
			return 0;
		}
	},
	methods: {
		approveDraft() {
			this.approveDraftDialog = true;
			this.$emit('approveDrat');
		},
		async updatePrescriptionCycle(data) {
			this.medicines.loading = true;
			try {
				await RascunhoPresenter.updateAproveitamentoReceita(
					data.boxId,
					{
						medicineId: data.medicineId,
						totalCycles: data.cycles
					}
				);
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Aproveitamento de receitas alterado com sucesso' });
				await this.getData();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao alterar aproveitamento de receitas' });
			} finally {
				this.medicines.loading = false;
			}
		},
		editPause() {
			this.$emit('editPause');
		},
		addItem(data) {
			this.$emit('addItem', data);
		},
		editItem(data) {
			this.$emit('editItem', data);
		},
		deleteItem(medicineId) {
			this.$emit('deleteItem', medicineId);
		}
	}
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px dashed #3B2C1B80;
	padding-bottom: 10px;

	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 30px;
	}
}
</style>
