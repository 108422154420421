<template>
	<v-container fluid grid-list-md class="pa-0">
		<h3 class="mt-3 mb-4">Geral</h3>
		<v-layout wrap>
			<v-flex xs12>
				<v-text-field
					box
					background-color="white"
					v-model="info.tradingName"
					label="Nome da casa de repouso"
					v-validate="{ required: true }"
					data-vv-name="Nome da casa de repouso"
					:error-messages="errors.collect('Nome da casa de repouso')"/>
			</v-flex>

			<v-flex xs12>
				<v-text-field
					box
					background-color="white"
					label="Nome do responsável"
					v-model="info.responsibleName"
					v-validate="{ required: false }"
					data-vv-name="Nome do responsável"
					:error-messages="[]"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-text-field
					background-color="white"
					box
					v-model="info.responsibleEmail"
					label="E-mail"
					data-vv-name="E-mail"
					type="email"
					@keyup="lowercase()"
					v-validate="{
						required: requiredWhenPaymentTypeIsOpenClose,
						email: true,
						regex: emailRegex
					}"
					:error-messages="errors.collect('E-mail')"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-text-field
					background-color="white"
					v-model="info.responsiblePhone"
					box
					v-mask="['(##) #####-####', '(##) ####-####']"
					label="Telefone"
					data-vv-name="Telefone"
					v-validate="{
						required: requiredWhenPaymentTypeIsOpenClose,
						telefone: true,
						regex: phoneRegex
					}"
					:error-messages="errors.collect('Telefone')"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-text-field
					background-color="white"
					v-model="info.federalRegistration"
					box
					mask="##.###.###/####-##"
					label="CNPJ"
					data-vv-name="CNPJ"
					v-validate="{
						required: requiredWhenPaymentTypeIsOpenClose,
						cnpj: true,
					}"
					:error-messages="errors.collect('CNPJ')"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-text-field
					background-color="white"
					v-model="info.companyName"
					box
					label="Razão social"
					data-vv-name="Razão social"
					v-validate="{ required: requiredWhenPaymentTypeIsOpenClose }"
					:error-messages="errors.collect('Razão social')"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-text-field
					type="number"
					min="0"
					background-color="white"
					v-model.number="info.municipalRegistration"
					box
					label="Inscrição municipal"
					data-vv-name="Inscrição municipal"
					v-validate="{ required: false, positive: true, regex: /^[0-9]+$/ }"
					:error-messages="errors.collect('Inscrição municipal')"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-text-field
					background-color="white"
					v-model="info.stateRegistration"
					@input="setIcmsTax"
					box
					label="Inscrição estadual"
					data-vv-name="Inscrição estadual"
					v-validate="{ required: requiredWhenPaymentTypeIsOpenClose }"
					:error-messages="errors.collect('Inscrição estadual')"/>
			</v-flex>

			<v-flex md3 xs12>
				<v-autocomplete
					box
					v-model="info.businessType"
					:items="restHomeBusinessTypes"
					item-value="value"
					item-text="text"
					no-data-text="Nenhum tipo disponível"
					background-color="white"
					label="Tipo do negócio"
					data-vv-name="Tipo do negócio"
					v-validate="{ required: true }"
					:error-messages="errors.collect('Tipo do negócio')"/>
			</v-flex>

			<v-flex md5 xs12>
				<v-autocomplete
					box
					v-model="info.simpleNationalTax"
					:items="[
						{ text: 'Sim', value: true },
						{ text: 'Não', value: false },
					]"
					item-value="value"
					item-text="text"
					no-data-text="Nenhum tipo disponível"
					background-color="white"
					label="Adepto ao Simples nacional"
					data-vv-name="Adepto ao Simples nacional"
					v-validate="{ required: false }"
					:error-messages="errors.collect('Adepto ao Simples nacional')"/>
			</v-flex>
			<v-flex md4 xs12>
				<v-autocomplete
					box
					v-model="info.icmsTax"
					:items="[
						{ text: 'Sim', value: true },
						{ text: 'Não', value: false },
					]"
					item-value="value"
					item-text="text"
					no-data-text="Nenhum tipo disponível"
					background-color="white"
					label="Contribuinte ICMS"
					data-vv-name="Contribuinte ICMS"
					v-validate="{ required: false }"
					:error-messages="errors.collect('Contribuinte ICMS')"/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { BUSINESS_TYPES } from '@Consts/rest-home/business-types';
import { GeneralFieldsUtil } from '@Util/general-fields';

export default {
	name: 'RestHomeGeneralDataInfo',
	inject: ['$validator'],
	props: {
		info: {
			required: false,
			type: Object,
			default: () => {}
		},
		requiredWhenPaymentTypeIsOpenClose: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			phoneRegex: GeneralFieldsUtil.phoneRegexValidationWithMask(),
			emailRegex: GeneralFieldsUtil.emailRegexValidation()
		};
	},
	methods: {
		setIcmsTax(val) {
			if (val)
				this.info.icmsTax = true;
			else
				this.info.icmsTax = false;
		},
		lowercase() {
			if (this.info.responsibleEmail)
				this.info.responsibleEmail = this.info.responsibleEmail.toLowerCase();
		}
	},
	computed: {
		restHomeBusinessTypes() {
			return BUSINESS_TYPES;
		}
	}
};
</script>

<style lang="scss" scoped></style>
