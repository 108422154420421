<template>
	<div class=" px-md-5">

		<v-layout row>
			<v-flex grow pa-1>
				<h1
					class="font-weight-bold mb-2"
					style="font-family: Inter; font-size: 30px">
					Casas de repouso
				</h1>
				<p class="mb-0">Utilize os campos abaixo para filtrar as casas de repouso.</p>
			</v-flex>
			<v-flex shrink pa-1>
				<v-btn
					depressed
					:loading="loading"
					:disabled="loading"
					class="primary mx-0 pl-2 pr-3"
					@click="openCreateRestHomeDialog()">
					<v-icon small class="mr-2">add</v-icon>
					Adicionar
				</v-btn>
			</v-flex>
		</v-layout>

		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 lg9>
				<v-layout wrap>
					<v-flex xs12 md6>
						<v-text-field
							label="Buscar Casa de repouso"
							v-model="filters.search"
							placeholder="Digite alguma coisa"
							background-color="white"
							box
							append-icon="search"
							color="purple"/>
					</v-flex>

					<v-flex>
						<v-switch
							v-model="filters.onlyTrashed"
							label="Apenas excluídas" />
					</v-flex>
					<v-flex>
						<v-menu
							:close-on-content-click="false">
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									depressed
									class="filter-button"
									style="max-width: 200px">
									<span style="flex-grow: 1">+ Filtros</span>
									<v-icon small class="ml-3 mr-2">keyboard_arrow_down</v-icon>
								</v-btn>
							</template>

							<v-list>
								<v-divider
									class="my-2"></v-divider>
								<v-list-tile class="py-2" >
									<v-select
										v-model="filters.companyUnities"
										:items="companyUnities"
										:item-text="(item) => `${item.key}`"
										item-value="id"
										chips
										label="FIlial"
										multiple>
									</v-select>
								</v-list-tile>

								<v-divider
									class="my-2"></v-divider>

								<v-list-tile class="py-2" >
									<v-select
										v-model="filters.businessTypes"
										:items="businessTypes"
										item-text="text"
										item-value="value"
										chips
										label="Tipo de negócio"
										multiple>
									</v-select>
								</v-list-tile>

								<v-divider
									class="my-2"></v-divider>

								<v-list-tile class="py-2" >
									<v-select
										v-model="filters.responsiblePaymentTypes"
										:items="responsiblePaymentTypes"
										item-text="value"
										item-value="value"
										chips
										label="Responsável pelo pagamento"
										multiple>
									</v-select>
								</v-list-tile>
							</v-list>
						</v-menu>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>

		<v-card>
			<v-data-table
				:headers="headers"
				:items="items"
				:loading="loading"
				:no-data-text="loading ? 'Carregando...' : 'Nenhum item cadastrado'"
				:pagination.sync="pagination"
				:rows-per-page-items="[5, 10, 25, 100]"
				:total-items="total"

				no-results-text="Nenhum resultado encontrado">
				<template slot="items" slot-scope="props">
					<tr>
						<td>{{ props.item.tradingName }}</td>
						<td>{{ props.item.responsibleName || '-' }}</td>
						<td>{{ props.item.responsiblePhone || '-' }}</td>
						<td>{{ props.item.city }} - {{ parseStateNameToUF(props.item.uf) }} </td>
						<td>{{ props.item.paymentDeadline || 0 }} </td>
						<td>{{ props.item.advanceDelivery}} </td>
						<td>{{ props.item.businessType || '-'}} </td>
						<td>{{ props.item.paymentResponsible || '-'}} </td>
						<td>{{ getCompanyUnitById(props.item.companyUnitId)}} </td>
						<td v-if="!props.item.deletedAt">
							<v-menu offset-y>
								<template v-slot:activator="{ on }">
									<v-btn
										color="secondary"
										outline
										dark
										small
										@click.stop
										v-on="on">
										Ações
									</v-btn>
								</template>
								<v-list>
									<v-list-tile @click.stop="openUpdateRestHomeDialog(props.item.id)">
										<v-list-tile-action>
											<v-icon color="black">edit</v-icon>
										</v-list-tile-action>
										<v-list-tile-title >
											Editar dados
										</v-list-tile-title>
									</v-list-tile>

									<v-list-tile
										v-if="allowEditDiscountPercentage"
										@click.stop="openApplyDiscountRestHomeDialog(props.item.id)">
										<v-list-tile-action>
											<v-icon color="black">percent</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Editar desconto</v-list-tile-title>
									</v-list-tile>
								</v-list>
							</v-menu>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>

		<CreateRestHomeDialog
			v-if="openedCreateRestHomeDialog"
			@create-rest-home="createRestHome"
			@close="closeCreateRestHomeDialog" />

		<UpdateRestHomeDialog
			v-if="openedUpdateRestHomeDialog"
			:rest-home="restHome"
			@update-rest-home="updateRestHome"
			@close="closeUpdateRestHomeDialog" />

		<ApplyRestHomeDiscountDialog
			v-if="openedApplyDiscountRestHomeDialog"
			:discount="discount"
			:maxDiscountPercentage="discountMaxPercentage"
			@updateDiscount="updateDiscount"
			@close="openedApplyDiscountRestHomeDialog = false"/>

		<LoaderDialog v-if="loading"/>

	</div>
</template>

<script>
import { debounce, isEqual, first } from 'lodash';
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import { RestHomePresenter } from '@Presenter/v2/rest-home-presenter';
import { getCurrentCompanyUnit, unidades } from '@Config/unidades';
import { statesWithSymbol, getUFByStateName } from '@Consts/estados';
import { BUSINESS_TYPES } from '@Consts/rest-home/business-types';
import { RESPONSIBLE_PAYMENT_TYPES } from '@Consts/rest-home/responsible-payment';
import LoaderDialog from '@Views/componentes/loader-dialog.vue';
import store from '@Store/index';
import CreateRestHomeDialog from './dialog/rest-home-dialog-to-create';
import UpdateRestHomeDialog from './dialog/rest-home-dialog-to-update';
import ApplyRestHomeDiscountDialog from './update-discount-rest-home-dialog';

export default {
	name: 'CasaRepouso',
	components: {
		CreateRestHomeDialog,
		UpdateRestHomeDialog,
		ApplyRestHomeDiscountDialog,
		LoaderDialog
	},
	data: () => ({
		type: 'casa-repouso',
		loading: false,
		restHome: {},
		discount: {
			limitDate: null,
			discountPercentage: 0
		},
		discountMaxPercentage: 0,
		items: [],
		pagination: {
			descending: false,
			page: 1,
			rowsPerPage: 10,
			sortBy: 'id'
		},
		total: 0,
		filters: {
			onlyTrashed: false,
			search: null,
			businessTypes: [],
			paymentResponsible: [],
			companyUnities: [getCurrentCompanyUnit().id]
		},
		restHomeIdToBeUpdate: null,
		openedCreateRestHomeDialog: false,
		openedUpdateRestHomeDialog: false,
		openedApplyDiscountRestHomeDialog: false
	}),
	async created() {
		await this.getItems();
		this.filters.businessTypes = this.businessTypes.map(item => item.value);
	},
	computed: {
		businessTypes() {
			return BUSINESS_TYPES;
		},
		responsiblePaymentTypes() {
			return RESPONSIBLE_PAYMENT_TYPES;
		},
		companyUnities() {
			return unidades;
		},
		headers() {
			return [
				{ text: 'Nome', value: 'nome', sortable: true },
				{ text: 'Nome do responsável', value: 'nome_responsavel', sortable: false },
				{ text: 'Telefone do responsável', value: 'Telefone_responsavel', sortable: false },
				{ text: 'Localização', value: 'localizacao', sortable: false },
				{ text: 'Prazo (dias)', value: 'prazo_pagamento', sortable: false },
				{ text: 'Antecedencia entrega (dias)', value: 'antecedencia_entrega', sortable: false },
				{ text: 'Tipo de negócio', value: 'tipo_negocio', sortable: false },
				{ text: 'Responsável pelo pagamento', value: 'responsavel_pagamento', sortable: false },
				{ text: 'Filial', value: 'filial', sortable: false }
			];
		},
		allowEditDiscountPercentage() {
			return !!store.getters.allow_apply_discount_ilpi;
		}
	},
	methods: {
		getCompanyUnitIdFilter() {
			if (this.filters.companyUnities.length !== 1)
				return null;

			return first(this.filters.companyUnities);
		},
		parseStateNameToUF(stateName) {
			return getUFByStateName(stateName);
		},
		getCompanyUnitById(companyUnit) {
			return unidades.find(({ id }) => id === companyUnit).nome;
		},
		getItems: debounce(async function () {
			try {
				this.loading = true;
				const response = await RestHomePresenter.list({
					filter: {
						q: this.filters.search,
						onlyTrashed: this.filters.onlyTrashed,
						companyUnitId: this.getCompanyUnitIdFilter(),
						businessTypes: this.filters.businessTypes,
						responsiblePaymentTypes: this.filters.responsiblePaymentTypes
					},
					pagination: { ...this.pagination, perPage: this.pagination.rowsPerPage },
					sort: {
						sortDir: this.pagination.descending ? 'DESC' : 'ASC',
						sortBy: this.pagination.sortBy
					}
				});

				this.total = response.total;
				this.items = response.items;
			} catch (error) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: error.message || 'Erro ao buscar ilpis'
				});
			} finally {
				this.loading = false;
			}
		}, 1000),
		closeCreateRestHomeDialog() {
			this.openedCreateRestHomeDialog = false;
		},
		openCreateRestHomeDialog() {
			this.openedCreateRestHomeDialog = true;
		},
		async openUpdateRestHomeDialog(restHomeId) {
			this.loading = true;
			this.restHomeIdToBeUpdate = restHomeId;
			try {
				const restHomeResponse = await CasaRepousoPresenter.show(restHomeId);
				this.restHome = this.parseRequestToData(restHomeResponse);
				this.openedUpdateRestHomeDialog = true;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao buscar Casa de repouso',
					color: 'error'
				});
				return;
			} finally {
				this.loading = false;
			}
		},
		async openApplyDiscountRestHomeDialog(restHomeId) {
			this.loading = true;
			this.restHomeIdToBeUpdate = restHomeId;
			try {
				const restHomeResponse = await CasaRepousoPresenter.show(restHomeId);
				this.discount = {
					limit: null,
					discountPercentage: restHomeResponse.porcentagem_desconto
				};
				this.discountMaxPercentage = restHomeResponse.porcentagem_maxima_desconto;
				this.openedApplyDiscountRestHomeDialog = true;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao buscar Casa de repouso',
					color: 'error'
				});
				return;
			} finally {
				this.loading = false;
			}
		},
		async createRestHome(data) {
			const _data = this.parseDataToRequest(data);
			this.loading = true;
			try {
				await CasaRepousoPresenter.store(_data);
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Casa de repouso criada com sucesso!',
					color: 'success'
				});
				this.closeCreateRestHomeDialog();
				await this.getItems();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao criar Casa de repouso',
					color: 'error'
				});
			} finally {
				this.loading = false;
			}
		},

		async updateDiscount(data) {
			this.loading = true;
			try {
				await CasaRepousoPresenter.updateDiscount(
					this.restHomeIdToBeUpdate,
					{
						porcentagem_desconto: data.discountPercentage,
						data_limite: data.limitDate
					}
				);
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Porcentagem de desconto alterado com sucesso',
					color: 'success'
				});
				this.closeUpdateDiscountRestHomeDialog();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao atualizar porcentagem de desconto da Casa de repouso',
					color: 'error'
				});
			} finally {
				this.loading = false;
			}
		},

		async updateRestHome(data) {
			const _data = this.parseDataToRequest(data);
			this.loading = true;
			try {
				await CasaRepousoPresenter.update(
					this.restHomeIdToBeUpdate,
					_data
				);
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Casa de repouso atualizada com sucesso!',
					color: 'success'
				});
				this.closeUpdateRestHomeDialog();
				await this.getItems();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao atualizar Casa de repouso',
					color: 'error'
				});
			} finally {
				this.loading = false;
			}
		},
		closeUpdateRestHomeDialog() {
			this.openedUpdateRestHomeDialog = false;
		},
		closeUpdateDiscountRestHomeDialog() {
			this.openedApplyDiscountRestHomeDialog = false;
		},
		parseDataToRequest(data) {
			return {
				filial_id: getCurrentCompanyUnit().id,

				// info
				cnpj: data.info.federalRegistration,
				simples_nacional: data.info.simpleNationalTax,
				contribuinte_icms: data.info.icmsTax,
				tipo_negocio: data.info.businessType,
				razao_social: data.info.companyName,
				inscricao_municipal: data.info.municipalRegistration,
				inscricao_estadual: data.info.stateRegistration,
				nome: data.info.tradingName,
				nome_responsavel: data.info.responsibleName,
				email: data.info.responsibleEmail,
				telefone_responsavel: data.info.responsiblePhone,

				// delivery
				tipo_entrega: data.delivery.deliveryType,
				dias_antecedencia_entrega: data.delivery.daysInAdvanceForDelivery,

				// payment
				responsavel_pagamento: data.payment.responsabilityForPayment,
				tipo_pagamento: data.payment.type,
				dia_vencimento: data.payment.dueDay,
				dia_fechamento: data.payment.closingDay,
				prazo_pagamento: data.payment.expireToPayInDays,

				porcentagem_maxima_desconto: data.discount.maxDiscountPercentage,

				// address
				cep: data.address.zipcode,
				logradouro: data.address.street,
				bairro: data.address.neighborhood,
				cidade: data.address.city,
				estado: statesWithSymbol[data.address.state],
				numero: data.address.number,
				complemento: data.address.complement,
				observacao: data.address.observation,
				latitude: data.address.latitude,
				longitude: data.address.longitude
			};
		},

		parseRequestToData(request) {
			return {
				// info
				federalRegistration: request.cnpj,
				simpleNationalTax: request.simples_nacional,
				icmsTax: request.contribuinte_icms,
				businessType: request.tipo_negocio,
				companyName: request.razao_social,
				municipalRegistration: request.inscricao_municipal,
				stateRegistration: request.inscricao_estadual,
				tradingName: request.nome,
				responsibleName: request.nome_responsavel,
				responsibleEmail: request.email,
				responsiblePhone: request.telefone_responsavel,

				// delivery
				deliveryType: request.tipo_entrega,
				daysInAdvanceForDelivery: request.dias_antecedencia_entrega,

				maxDiscountPercentage: request.porcentagem_maxima_desconto,

				// payment
				responsabilityForPayment: request.responsavel_pagamento,
				type: request.tipo_pagamento,
				dueDay: request.dia_vencimento,
				closingDay: request.dia_fechamento,
				expireToPayInDays: request.prazo_pagamento,

				// address
				zipcode: request.cep,
				street: request.logradouro,
				neighborhood: request.bairro,
				city: request.cidade,
				state: request.estado,
				number: request.numero,
				complement: request.complemento,
				observation: request.observacao,
				latitude: request.latitude,
				longitude: request.longitude
			};
		}
	},
	watch: {
		filters: {
			async handler() {
				await this.getItems();
			},
			deep: true
		},
		pagination: {
			async handler(newVal, oldVal) {
				if (isEqual(newVal, oldVal) || !newVal)
					return;
				await this.getItems();
			},
			deep: true
		}

	}
};
</script>

<style>
</style>
