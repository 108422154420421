import $http from '@Config/axios';
import { onlyNumber } from '@Util/functions';
import $store from '@Store';
import { DefaultPresenter } from './default-presenter';

const prefix = 'casa_repouso';
const entity = 'Casa de repouso';

function sanitize(restHomeData) {
	return {
		...restHomeData,
		telefone_responsavel: restHomeData.telefone_responsavel ? onlyNumber(restHomeData.telefone_responsavel) : null,
		cnpj: restHomeData.cnpj ? onlyNumber(restHomeData.cnpj) : null,
		cep: onlyNumber(restHomeData.cep),
		simples_nacional: Boolean(restHomeData.simples_nacional),
		contribuinte_icms: Boolean(restHomeData.contribuinte_icms)
	};
}

/**
 *
 * @param {Number} restHomeId
 * @param {{porcentagem_desconto: Number, data_limite: String}} discountData
 * @returns
 */
function updateDiscount(restHomeId, discountData) {
	return $http.patch(`${prefix}/${restHomeId}/porcentagem-desconto`, discountData);
}

export const CasaRepousoPresenter = {
	...DefaultPresenter({ prefix, entity }),
	store(casaRepouso) {
		return new Promise((resolve, reject) => {
			$http.post(prefix, sanitize(casaRepouso))
				.then(response => {
					$store.dispatch('SHOW_SNACKBAR', { message: 'Casa de repouso salva com sucesso!' });
					resolve(response);
				})
				.catch(response => reject(response));
		});
	},
	update: (restHomeId, data) => $http.put(`${prefix}/${restHomeId}`, sanitize(data)),
	orcamentos: id => $http.get(`${prefix}/${id}/orcamentos`),
	updateDiscount
};
