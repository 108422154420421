import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = '/produtos';
const prefixV2 = 'v2/products';

function mountProductData(params) {
	const body = {
		type: params.type,
		name: params.name,
		presentation: params.presentation,
		description: params.description,
		quantity: params.quantity,
		quantityForSale: params.quantityForSale,
		unitOfMeasurementSymbol: params.unitOfMeasurementSymbol,
		unitOfMeasurementForSaleSymbol: params.unitOfMeasurementForSaleSymbol,
		unitOfMeasurementSymbolContainer: params.unitOfMeasurementSymbolContainer,
		ncm: params.ncm || null,
		gtins: params.gtins.map(gtin => gtin.toString()),
		manufacturerId: params.manufacturer.id,
		manufacturerName: params.manufacturer.name,
		groupId: params.group.id,
		groupName: params.group.name,
		brand: params.brand,
		categoryId: params.category.id,
		storageCondition: params.storageCondition,
		associatedConditionId: params.associatedConditionId || null,
		height: params.height || null,
		width: params.width || null,
		length: params.length || null,
		grossWeight: params.grossWeight || null,
		netWeight: params.netWeight || null,
		umbrellaItemId: params.umbrellaItemId || null,
		prices: params.prices.map(item => ({
			value: Number(item.value),
			originalValue: item.originalValue,
			layerId: item.layerId
		})),
		availabilities: params.availabilities.map(item => ({
			status: item.status,
			originalStatus: item.originalStatus,
			companyUnitId: `${item.companyUnitId}`
		}))
	};

	if (params.medicine) {
		const referenceDrug = params.medicine.referenceDrug;
		const prescriptionRule = params.medicine.prescriptionRule;

		body.drug = {
			dosageFormId: params.medicine.dosageFormId,
			pbmId: params.medicine.pbmId || null,
			referenceDrugId: referenceDrug ? referenceDrug.id : null,
			referenceDrugName: referenceDrug ? referenceDrug.name : null,
			anvisaId: params.medicine.anvisaId || null,
			routeAdministrationIds: params.medicine.routeAdministrationIds || null,
			prescriptionRuleId: prescriptionRule ? prescriptionRule.id : null,
			prescriptionRuleClassification: prescriptionRule ? prescriptionRule.classification : null,
			stripe: params.medicine.stripe || null,
			type: params.medicine.type || null,
			atcId: params.medicine.atcId || null,
			pnu: params.medicine.pnu,
			ncm: params.medicine.ncm || null,
			pmc: params.medicine.pmc || null,
			pf: params.medicine.pf || null,
			compositions: params.medicine.compositions.map(composition => ({
				concentration: composition.concentration,
				unitOfMeasurementSymbol: composition.unitOfMeasurementSymbol,
				activeIngredientId: composition.activeIngredient.id,
				activeIngredientName: composition.activeIngredient.name,
				activeIngredientDescription: composition.activeIngredient.description
			})),
			properties: Object.entries(params.medicine.properties)
				.map(([chave, valor]) => ({ name: chave, value: valor }))
		};
	}
	return body;
}

export const ProductPresenter = {
	getProductById: productId => $http.get(`${prefix}/${productId}`),
	getProducts: params => $http.get(buildQueryParams(prefix, {
		...params,
		sortDirection: params.sortDirection ? 'DESC' : 'ASC'
	})),
	createProduct: params => $http.post(prefix, mountProductData(params)),
	updateProduct: (productId, params) => $http.put(`${prefix}/${productId}`, mountProductData(params)),
	getProductBySimilarity: ({ productId, compareQuantity }) => $http.get(buildQueryParams(`${prefixV2}/${productId}/similars`, compareQuantity ? { compareQuantity } : {}))
};
