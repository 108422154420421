<template>
	<section>
		<div class="header">
			<h3>Medicamentos</h3>

			<v-dialog
				v-if="!isOriginSubcription && canApproveChanges"
				persistent
				scrollable
				v-model="confirmApproveChangesDialog"
				width="390"
				transition="fade">

				<template v-slot:activator="{ on }">
					<v-btn
						color="primary"
						class="px-4 ml-auto"
						v-on="on"
						@click.stop="confirmApproveChangesDialog = true">
						<v-icon small class="ml-2">check</v-icon>
						Aprovar rascunho
					</v-btn>
				</template>

				<section class="farme-dialog">
					<header class="farme-dialog-header font-weight-bold">
						Aprovar alterações?
					</header>

					<main class="farme-dialog-body">

						<p>Tenha certeza que cumpriu os passos abaixo antes de continuar:</p>

						<ul>
							<li>Confirmo que validei o rascunho feito pelo atendimento</li>
						</ul>
					</main>

					<footer class="farme-dialog-footer">
						<v-spacer></v-spacer>
						<v-btn color="secondary" flat @click="confirmApproveChangesDialog = false">Cancelar</v-btn>
						<v-btn color="primary" @click="approveDraft">
							Confirmar
						</v-btn>
					</footer>
				</section>
			</v-dialog>
		</div>

		<MedicineTable
			:items="items"
			:isPrescriptionVisible="true"
			:isPrescriptionReadonly="!canApproveChanges"
			:isPrescriptionReuseVisible="!extendedCycle"
			:prescriptions="prescriptions"
			:isOriginSubcription="isOriginSubcription"
			:subscriptionMode="subscription.modalidade"
			@reload="$emit('reload')"
			@updatePrescriptionCycle="updatePrescriptionCycle"/>
	</section>
</template>

<script>
import { RascunhoPresenter } from '@Presenter/alteracoes-presenter';
import MedicineTable from './table.vue';

export default {
	name: 'PharmaceuticDialogMedicineSection',
	components: { MedicineTable },
	props: {
		items: {
			required: false,
			type: Array,
			defualt: () => ([])
		},
		subscription: {
			required: true,
			type: Object
		},
		canApproveChanges: {
			required: false,
			type: Boolean,
			default: false
		},
		prescriptions: {
			required: false,
			type: Array,
			default: () => []
		},
		isOriginSubcription: {
			required: true,
			type: Boolean
		},
		extendedCycle: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			addMedicineDialog: false,
			searchMedicineDialog: false,
			isLoadingPrintQuotation: false,
			confirmApproveChangesDialog: false
		};
	},
	methods: {
		approveDraft() {
			this.approveDraftDialog = true;
			this.$emit('approveDrat');
		},
		async updatePrescriptionCycle(data) {
			this.medicines.loading = true;
			try {
				await RascunhoPresenter.updateAproveitamentoReceita(
					data.boxId,
					{
						medicineId: data.medicineId,
						totalCycles: data.cycles
					}
				);
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Aproveitamento de receitas alterado com sucesso' });
				await this.getData();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao alterar aproveitamento de receitas' });
			} finally {
				this.medicines.loading = false;
			}
		},
		editPause() {
			this.$emit('editPause');
		},
		addItem(data) {
			this.$emit('addItem', data);
		},
		editItem(data) {
			this.$emit('editItem', data);
		},
		deleteItem(medicineId) {
			this.$emit('deleteItem', medicineId);
		}
	}
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px dashed #3B2C1B80;
	padding-bottom: 10px;

	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 30px;
	}
}
</style>
