<template>
	<Dialog :titulo="tituloOpcional ? tituloOpcional : 'Confirmação'" subtitulo="Tenha certeza que cumpriu os passos abaixo antes de continuar" btn_acao="Confirmar"
		:loading="loading" @acao="confirmar" @close="close">
		<p v-for="(text, i) in opcoes" :key="i">&bull; {{ text }}</p>
	</Dialog>
</template>

<script>
export default {
	name: 'DialogConfirmacao',
	props: {
		opcoes: {
			type: Array,
			required: true
		},
		request: {
			type: Function,
			required: true
		},
		tituloOpcional: {
			type: String
		}
	},
	data: () => ({
		loading: false
	}),
	methods: {
		confirmar() {
			this.loading = true;
			this.request()
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					this.close();
					this.loading = false;
				});
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>
