<template>
	<v-app>
		<div class="loading-app" :class="!loading ? 'ready' : ''" v-if="loading_allow_html">
			<h1>
				<img width="100px" :src="logo" alt="logo-farme">
			</h1>
			<h4>Por favor, aguarde.</h4>
			<v-progress-linear :indeterminate="true" color="white" style="width: 200px;"></v-progress-linear>
		</div>

		<transition name="fade" mode="out-in">
			<router-view v-if="!loading" />
		</transition>

		<v-snackbar :timeout="snackbar.timeout" :bottom="true" :right="true" :auto-height="true"
			:multi-line="false" :color="snackbar.color" v-model="snackbarVisible">
			<span :style="`color: ${snackbar.textColor}!important;`">{{ snackbar.message }}</span>
			<v-btn flat dark @click.native="setSnackbarVisible"> OK </v-btn>
		</v-snackbar>
	</v-app>
</template>

<script>
import logo from '@Assets/images/farme-logo-animada.gif';
import unleash from '@Util/unleash';

export default {
	name: 'App',
	methods: {
		setSnackbarVisible() {
			this.$store.dispatch('HIDE_SNACKBAR');
		}
	},
	data: () => ({
		loading: true,
		loading_allow_html: true,
		logo
	}),
	mounted() {
		window.addEventListener('load', () => {
			setTimeout(() => {
				this.loading = false;
			}, 500);

			setTimeout(() => {
				this.loading_allow_html = false;
			}, 5000);
		});
		unleash.on(
			'update',
			() => {
				this.$store.dispatch('FETCH_PERMISSIONS', unleash);
				this.$store.dispatch('FETCH_FEATURE_FLAGS', unleash);
			}
		);
	},
	computed: {
		snackbar() {
			return this.$store.getters.getSnackbar;
		},
		snackbarVisible: {
			get() {
				return this.$store.getters.getSnackbar.visible;
			},
			set() {
				this.setSnackbarVisible();
			}
		}
	}
};
</script>

<style lang="scss">
@import "@Assets/css/global.scss";
@import "@Assets/css/spacing-responsive.scss";
@import "@Assets/css/settings.scss";

.loading {
	min-height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Transições da rota */
.page-enter-active,
.page-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.page-enter,
.page-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loading-app {
  &.ready {
    opacity: 0;
    z-index: -1000;
    transition: all 0.5s;
  }
}

/* CSS padrão */
html,
body {
  height: 100%;
  overflow-y: auto;
  color: $text;
}

@font-face {
	font-family: 'ITC';
	src: url('./assets/fonts/ITC.woff');
}

h1,
h2,
h3,
.titulo {
  font-family: "ITC", sans-serif;
}

.montserrat {
	font-family: "Montserrat Alternates", sans-serif;
}

.poppins {
	font-family: "Poppins", sans-serif;
}

.inter {
	font-family: "Inter", sans-serif;
}

.sans {
	font-family: Open Sans, sans-serif;
}

.roboto {
	font-family: Roboto, sans-serif;
}

.pointer {
  cursor: pointer !important;

  .v-chip__content {
    cursor: pointer !important;
  }
}

.cursor-default {
	cursor: default !important;
}

.user-select {
  user-select: none;
}

.inline-block {
  display: inline-block;
}

.badge {
  font-family: "Poppins", sans-serif;
  display: inline-block;
  min-width: 21px;
  padding: 5px 7px;
  text-align: center;
  border-radius: 50px;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.fit-content {
  max-width: max-content;
}

.purple-title {
  background: $purple_strong !important;
  background-size: cover;
  color: #fff;

  &.v-card__title {
    flex: 0 0 auto !important;
  }

  .align-end {
    display: flex;
    justify-content: flex-end;
  }
}

.green-title {
	//url("./assets/images/background-green.png") - Purple-strong --> #624d65
  background-size: cover;
  color: #fff;

  .align-end {
    display: flex;
    justify-content: flex-end;
  }
}

.display-flex {
  display: flex;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.space-between {
	display: flex;
	justify-content: space-between;
}

.error-2-lines {
  .v-text-field__details {
    height: 24px !important;
  }
}
/* Vuetify */
.application--wrap {
  background-color: $primary_light;
}

.v-dialog {
  border-radius: 10px !important;
  overflow: unset;
}

.v-card {
  border-radius: 7px !important;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.07);
  transition: box-shadow 0.3s;

  .v-card__title {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
  }
}

.v-avatar {
  user-select: none;
  background: $primary;
  background-size: cover;

  .headline {
    font-family: "Montserrat Alternates", sans-serif !important;
  }
}

.v-btn {
  text-transform: none !important;

  &.v-btn--outline {
    font-weight: 400 !important;
  }

  .v-btn__content {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
  }
}

.v-dialog--fullscreen {
  border-radius: 0 !important;
}

.v-tooltip__content {
  background-color: $primary !important;
	color: $green !important;
  box-shadow: none !important;
  text-align: center;

  &.top {
    &:after {
      border: solid transparent !important;
      top: 100% !important;
      left: 50% !important;
      content: " " !important;
      height: 0 !important;
      width: 0 !important;
      position: absolute !important;
      pointer-events: none !important;
      border-top-color: $purple !important;
      border-width: 4px !important;
      margin-left: -4px !important;
    }
  }

  &.bottom {
    &:after {
      border: solid transparent !important;
      bottom: 100% !important;
      left: 50% !important;
      content: " " !important;
      height: 0 !important;
      width: 0 !important;
      position: absolute !important;
      pointer-events: none !important;
      border-bottom-color: $purple !important;
      border-width: 4px !important;
      margin-left: -4px !important;
    }
  }
}

.v-text-field--outline > .v-input__control > .v-input__slot {
  border-width: 1px !important;
}

.v-input--switch {
  .v-input--selection-controls__input {
    height: 22px !important;

    .v-input--switch__track {
      border-radius: 10px !important;
      height: 20px !important;
      top: 0 !important;
      opacity: 1 !important;
    }

    .v-input--switch__thumb {
      color: #fff !important;
      caret-color: #fff !important;
      top: 1px !important;
      height: 18px !important;
      width: 18px !important;
      transform: translate(3px, 0);

      &.purple--text,
      &.green--text,
      &.accent--text,
      &.success--text,
      &.error--text,
      &.warning--text,
      &.info--text,
      &.primary--text {
        color: #fff !important;
        caret-color: #fff !important;
      }
    }
  }

  &.v-input--is-dirty .v-input--switch__thumb {
    transform: translate(17px, 0) !important;
  }
}

.v-toolbar.header {
  background: #fff;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.05);

  .v-toolbar__content {
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto !important;
    padding: 16px;

    @media screen and (min-width: $break-point-md) {
      padding: 16px 24px;
    }

    .badge {
      transform: translateY(-2px);
    }

    .titulo-header {
      @media screen and (max-width: $break-point-md) {
        width: 100%;
      }
    }

    .busca {
      @media screen and (min-width: $break-point-md) {
        width: 300px;
        margin-left: 75px;
      }

      @media screen and (max-width: $break-point-md) {
        width: 100%;
      }
    }

    .botoes-navbar {
      display: flex;
      flex-wrap: wrap;
      min-height: 100%;
      align-items: center;
    }
  }
}

.q-row, .q-column {
	display: flex;
	flex-wrap: wrap;
}

.q-column {
	flex-direction: column;
}

.items-start {
	display: flex;
	align-items: start;
}

.items-center {
	display: flex;
	align-items: center;
}

.items-end {
	display: flex;
	align-items: flex-end;
}

.justify-center {
	justify-content: center;
}

.justify-space-between {
	justify-content: space-between;
}

.q-row {
	& > .col-12 {
		height: auto;
		width: 100%;
	}
}

.text-center {
	text-align: center;
}

.fit {
	width: 100%;
	height: 100%;
}

.full-width {
	width: 100%;
}

.full-height {
	height: 100%;
}

.text-bold {
	font-weight: bold;
}

.text-bold-medium {
	font-weight: 500;
}

button {
	&.v-btn--small {
		i {
			&.fas {
				font-size: 12px;
			}
		}
	}
}

.v-datatable {
	tbody {
		.buttons {
			display: flex;
			justify-content: center;
			align-items: center;

			button {
				margin: 2px;
			}

			i {
				&.fas {
					font-size: 20px;
				}
			}
		}
	}
}

.theme--light.v-toolbar {
	background: none;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.box {
	padding-top: 14px;
	position: relative;
	background: rgba(0,0,0,.06);
	width: 100%;
	height: 100%;
	min-height: 56px;
	border-bottom: 2px solid rgba(0,0,0,.42);
	transition: all 0.3s;

	&:hover {
		cursor: pointer;
		background: rgba(0,0,0,.12);
		border-bottom: 2px solid $purple_strong;
	}

	.v-label {
		position: absolute;
    top: 50%;
    transform: translateY(-50%);
		color: rgba(0,0,0,.54);

		&.with-items {
			transform: translateY(-150%) scale(.75);
		}
	}
}

.column.sortable.text-xs-center {
	transform: translateX(-10px);
}

.spacerQuotationStyle {
	border: 3px dotted rgba(255, 200, 117, 0.4);
	width: 100%;
	margin: 32px 0px;
}

.inputQuotationStyle {
	background-color: white !important;
	border-radius: 4px 4px 0px 0px;

	.theme--light.v-text-field--box>.v-input__control>.v-input__slot {
		background: white !important
	}
}

.farme-dialog {
	width: 100%;
	background-color: #FFEACC !important;
	border-radius: 15px!important;

	.farme-dialog-header {
		border-radius: 15px 15px 0 0 !important;
		background-color: #FFDEAF;
		font-size: 20px;
		line-height: 10px;
		font-weight: 400;
		font-family: 'ITC';
		padding: 35px 20px;
		letter-spacing: -0.025em;
	}
	.farme-dialog-body {
		padding: 20px 30px;
		overflow-y: scroll;
	}
	.farme-dialog-footer {
		display: flex;
		padding: 0px 30px 10px;
		border-radius: 0 0 15px 15px !important;
	}
}
</style>
