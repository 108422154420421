<template>
	<section>
		<div class="header">
			<h3>Dados sobre o Ciclo</h3>
		</div>

		<v-container fluid grid-list-md class="px-0">
			<v-layout row wrap>
				Inicio do ciclo: {{ this.startDate | formatDate('DD/MM/YYYY') }}<br>
				Tempo do ciclo: {{ this.days }} dias<br>
				Fim do ciclo: {{ this.endDate | formatDate('DD/MM/YYYY') }}<br>
			</v-layout>
		</v-container>
	</section>
</template>

<script>
import moment from 'moment';

export default {
	name: 'PharmaceuticBoxDataSection',
	props: {
		startDate: {
			required: true,
			type: String
		},
		endDate: {
			required: true,
			type: String
		},
		days: {
			required: false,
			type: Number,
			default: 30
		}
	},
	data() {
		return {
			openedCycleDialog: false
		};
	},
	computed: {
		startCycle() {
			return moment(this.startDate).format('DD/MM/YYYY');
		},
		endCycle() {
			return moment(this.startDate).format('DD/MM/YYYY');
		},
		nextBoxDate() {
			return moment(this.endDate).add(1, 'days').format('DD/MM/YYYY');
		},
		qtyCycles() {
			return Number(this.days / 30);
		}
	},
	methods: {
		openCycleDialog() {
			this.openedCycleDialog = true;
		},
		closeCycleDialog() {
			this.openedCycleDialog = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px dashed #3B2C1B80;
	padding-bottom: 10px;

	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 30px;
	}
}
</style>
