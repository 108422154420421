<template>
	<section style="width: 100%;">
		<h5 class="inter text-bold body-1 mb-4">Dados da entrega do pedido</h5>
		<v-container fluid grid-list-md class="pa-0">
			<v-layout wrap>
				<v-flex md4 xs12>
					<v-text-field
						v-model="address.zipCode"
						box
						background-color="white"
						label="CEP *"
						append-icon="search"
						mask="#####-###"
						data-vv-name="CEP"
						:error-messages="errors.collect('CEP')"
						v-validate="{ required: true, numeric: true, min: 8 }"
						@click:append="search()"
						@keyup.enter="search()"
						@blur="search()"/>
				</v-flex>
				<v-flex md6 xs12>
					<v-text-field
						v-model="address.street"
						box
						background-color="white"
						label="Rua"
						data-vv-name="Rua"
						:error-messages="errors.collect('Rua')"
						v-validate="{ required: true }"/>
				</v-flex>
				<v-flex md2 xs12>
					<v-text-field
						v-model="address.number"
						box
						background-color="white"
						label="Número *"
						data-vv-name="Número"
						:error-messages="errors.collect('Número')"
						v-validate="{ required: true }"/>
				</v-flex>
				<v-flex md4 xs12>
					<v-text-field
						v-model="address.neighborhood"
						box
						background-color="white"
						label="Bairro"
						data-vv-name="Bairro"
						:error-messages="errors.collect('Bairro')"
						v-validate="{ required: true }"/>
				</v-flex>

				<v-flex md4 xs12>
					<v-text-field
						v-model="address.city"
						box
						background-color="white"
						label="Cidade"
						data-vv-name="Cidade"
						:error-messages="errors.collect('Cidade')"
						v-validate="{ required: true }"/>
				</v-flex>

				<v-flex md4 xs12>
					<v-autocomplete
						box
						:items="states"
						item-value="id"
						item-text="text"
						no-data-text="Nenhum estado encontrado"
						v-model="address.state"
						background-color="white"
						label="Estado"
						data-vv-name="Estado"
						v-validate="{ required: true }"
						:error-messages="errors.collect('Estado')"/>
				</v-flex>

				<v-flex xs12>
					<v-text-field
						v-model="address.complement"
						box
						background-color="white"
						label="Complemento"/>
				</v-flex>
			</v-layout>
		</v-container>
	</section>
</template>

<script>
import { EnderecoPresenter } from '@Presenter/endereco-presenter';
import { statesWithSymbol } from '@Consts/estados';

export default {
	name: 'DeliveryAddressSection',
	$_veeValidate: { validator: 'new' },
	props: {
		address: {
			required: true,
			type: Object
		}
	},
	computed: {
		states() {
			const statesAbbr = Object.keys(statesWithSymbol);
			return statesAbbr.map(abbr => ({ id: abbr, text: statesWithSymbol[abbr] }));
		}
	},
	methods: {
		async search() {
			const zipcode = this.$functions.onlyNumber(this.address.zipCode);
			if (!zipcode)
				return;

			if (zipcode.length !== 8) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'CEP inválido'
				});
			}
			this.loadingZipcode = true;
			try {
				const addressResponse = await EnderecoPresenter.getAddressByZipcode(zipcode);
				this.address.zipCode = addressResponse.cep;
				this.address.neighborhood = addressResponse.bairro;
				this.address.street = addressResponse.logradouro;
				this.address.city = addressResponse.cidade;
				this.address.state = addressResponse.estado;
			} catch (error) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: error.message || 'Erro ao buscar CEP'
				});
			} finally {
				this.loading = false;
			}
		},
		async validate() {
			const isValid = await this.$validator.validateAll();
			return !!isValid;
		}
	},
	watch: {
		'address.state': function () {
			this.$emit('addressUpdated', this.address);
		}
	}
};
</script>

<style scoped>
.address-box{
	background-color: #FFF;
	border-radius: 3.43px;
	border: solid 1.5px #2DCE71;

}
</style>
