<template>
	<section>
		<div class="header">
			<h3>Dados sobre o Ciclo</h3>

			<v-btn
				v-if="permite_alterar_ciclo"
				color="primary"
				@click="openCycleDialog">
				Alterar ciclo
				<v-icon small>edit</v-icon>
			</v-btn>
		</div>

		<v-container fluid grid-list-md class="px-0">
			<v-layout row wrap>
				<br>Inicio do ciclo: {{ this.startDate | formatDate('DD/MM/YYYY') }}
				<br>Tempo do ciclo: {{ this.days }} dias
				<br>Fim do ciclo: {{ this.endDate | formatDate('DD/MM/YYYY') }}
				<template v-if="this.boxServiceValue > 0">
					<br> Taxa de entrega: {{ this.boxServiceValue | dinheiro }}
				</template>
			</v-layout>
		</v-container>

		<v-dialog persistent scrollable v-model="openedCycleDialog" transition="fade" max-width="600px">
			<transition>
				<CycleDialog
					v-if="openedCycleDialog"
					:startDate="this.startCycle"
					:boxDays="this.days"
					@close="closeCycleDialog"
					@dataCycleChanged="changeCycle"/>
			</transition>
		</v-dialog>
	</section>
</template>

<script>
import moment from 'moment';
import { featureFlags } from '@Consts/feature-flags';
import CycleDialog from './cycle-dialog.vue';

export default {
	name: 'DraftDialogBoxDataSection',
	props: {
		startDate: {
			required: true,
			type: String
		},
		endDate: {
			required: true,
			type: String
		},
		days: {
			required: false,
			type: Number,
			default: 30
		},
		boxServiceValue: {
			required: false,
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			openedCycleDialog: false
		};
	},
	components: {
		CycleDialog
	},
	computed: {
		startCycle() {
			return moment(this.startDate).format('DD/MM/YYYY');
		},
		endCycle() {
			return moment(this.startDate).format('DD/MM/YYYY');
		},
		nextBoxDate() {
			return moment(this.endDate).add(1, 'days').format('DD/MM/YYYY');
		},
		qtyCycles() {
			return Number(this.days / 30);
		},
		permite_alterar_ciclo() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.change_size_box_cycle);
		}
	},
	methods: {
		openCycleDialog() {
			this.openedCycleDialog = true;
		},
		closeCycleDialog() {
			this.openedCycleDialog = false;
		},
		changeCycle(data) {
			this.$emit('cycleChanged', data);
		}
	}
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px dashed #3B2C1B80;
	padding-bottom: 10px;

	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 30px;
	}
}
</style>
