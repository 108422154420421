import $http from '@Config/axios';
import { onlyNumber } from '@Util/functions';
import { buildQueryParams } from 'luiz-fns';

const prefix = 'prescritores';

export const PrescriberPresenter = {
	/**
	 * Get a list of prescribers
	 *
	 * @param {object} searchParams
	 * @param {String} searchParams.search
	 * @returns
	 */
	list: (searchParams = {}) => $http.get(buildQueryParams(prefix, { search: searchParams.search })),
	/**
	 * Create a new prescriber
	 *
	 * @param {object} body
	 * @param {String} body.uf
	 * @param {String} body.name
	 * @param {String} body.typeOfRegister
	 * @param {String|Number} body.number
	 * @returns
	 */
	create: (body = {}) => $http.post(prefix, {
		name: body.name,
		number: body.number ? onlyNumber(body.number) : null,
		uf: body.uf,
		typeOfRegister: body.typeOfRegister
	})
};
