<template>
	<section>
		<v-data-table :items="tableItems" v-model="selected" :headers="headers" :loading="loading" :total-items="total"
			class="custom-data-table fixed-columns" hide-actions :disable-initial-sort="true" :pagination.sync="pagination"
			rows-per-page-text="Items por página" :rows-per-page-items="itemsPerPageOptions"
			no-results-text="Nenhum resultado encontrado"
			:no-data-text="loading ? 'Carregando...' : 'Nenhum intem encontrado'">
			<template v-slot:headers="props">
				<tr>
					<th width="10px">
						<v-checkbox color="primary" :input-value="props.all" :indeterminate="props.indeterminate" hide-details
							@click.stop="toggleAll"></v-checkbox>
					</th>
					<th v-for="header in props.headers" :key="header.text" :width="header.width" class="column" :class="[
						header.sortable ? 'sortable' : '',
						pagination.descending ? 'desc' : 'asc',
						header.value === pagination.sortBy ? 'active' : ''
					]"
						@click="changeSort(header)">

						<v-tooltip top v-if="header.tooltip" max-width="350px">
							<template v-slot:activator="{ on }">
								<v-icon v-if="header.sortable" small>arrow_upward</v-icon>
								<span v-on="on">
									{{ header.text }}
									<v-icon small color="warning">info</v-icon>
								</span>
							</template>
							<span>{{header.tooltipText}}</span>
						</v-tooltip>

						<span v-else>
							<v-icon v-if="header.sortable" small>arrow_upward</v-icon>
							{{ header.text }}
						</span>
					</th>
				</tr>
			</template>

			<template v-slot:items="props">
				<tr :active="props.selected" :style="`background-color: ${getBackgroundColorRow(props.item)};`">
					<td @click="props.selected = !props.selected">
						<v-checkbox v-if="!isDeleted(props.item)" :input-value="props.selected" primary hide-details></v-checkbox>
					</td>
					<td class="text-left">
						{{ props.item.medicineId }}
					</td>
					<td class="text-left">
						<section style="width: 180px">
							<div style="display: flex; flex-direction: row; gap: 4px">
								<strong>{{ props.item.name }}</strong>
								<MedicamentoInfoIcons
									style="z-index: 999;"
									:medicamento-pausado="props.item.medicamento_pausado"
									:produto-referencia="props.item.medicamento.produto_referencia"
									:produto="props.item.name"
									:descricao-tipo-produto="props.item.medicamento.descricao_tipo_produto"
									:portaria_344_98="props.item.medicamento.portaria_344_98"
									:fora-box="props.item.medicamento.fora_da_box"
									:refrigerado="props.item.medicamento.refrigerado"
									:pbm="props.item.medicamento.pbm"
									:beneficio-pbm="props.item.medicamento.beneficio_pbm"/>
							</div>
							{{ props.item.presentation }}
						</section>
					</td>
					<td class="text-center active-ingredient">
						<p>{{ props.item.activeIngredient }}</p>
					</td>
					<td class="text-center">
						{{ props.item.type }}
					</td>

					<td class="text-center">
						<div style="min-width: 200px;">
							<strong>{{ getFrequencyPosology(props.item.posology) }}</strong> <br>

							<template v-if="getDetailsFromPosologyFrequence(props.item.posology)">
								{{ getDetailsFromPosologyFrequence(props.item.posology) }}
							</template>
						</div>
					</td>
					<td class="text-center">
						<ul style="list-style: none;width: 200px;">
							<li v-for="(hour, index) of getItemsPosology(props.item.posology)" :key="index">{{ hour }}</li>
						</ul>
					</td>
					<td class="text-left">
						<div style="width: 200px;display:flex; justify-content: center;">
							<p style="margin: 0; text-align: center;">
								{{ getPackageQuantity(props.item.packageQuantity) }}
							</p>
						</div>
					</td>
					<td class="text-left">
						<div
							style="width: 200px;display:flex; justify-content: center;">
							<p v-if="props.item.quantityPurchased > 0" style="margin: 0; text-align: center;">
								{{ getPackageQuantityPurchased(props.item.quantityPurchased) }}
							</p>
							<p v-else style="margin: 0; text-align: center;">
								---
							</p>
						</div>
					</td>
					<td class="text-center">{{ props.item.typeOfPrescription || '---' }}</td>
					<td class="text-center">{{ !!props.item.isPbm ? 'Sim' : 'Não' }}</td>
					<td class="text-center">{{ getStock(props.item) }}</td>
					<td class="text-center">{{ getIndividualPrice(props.item) | dinheiro }}</td>
					<td class="text-center total-value">{{ getFinalPrice(props.item) }}</td>
					<td class="text-center">
						<span v-if="props.item.isControlled && subscriptionIsIntegral && isPrescriptionReuseVisible">
							<PrescriptionReuseCycle  style="width: 130px;" newDesign
								:desabilitado="isDeleted(props.item) || isPaused(props.item)" :eRascunho="true"
								:boxId="getOriginalItem(props.item.id).box.id" :medicamentoId="props.item.medicineId"
								:quantidade="props.item.prescriptionCycles" />
						</span>
						<span v-else>
							---
						</span>
					</td>
					<td class="text-center text-wrap">
						<p style="width: 200px!important;">{{ getAdditionalInfo(props.item) }}</p>
					</td>
					<td>
						<div style="display: flex;justify-content: end;align-items: center;">
							<EditItemDialog v-if="canEditItem(props.item)" :subscriptionMode="subscriptionMode"
								:item="getOriginalItem(props.item.id)"
								@editItem="editItem" />

							<PauseItemDialog v-if="canPauseItem(props.item)" :item="getOriginalItem(props.item.id)"
								:initialDateToPause="initialDateToPause" @pauseChanged="pauseChanged" />

							<DeleteItemDialog v-if="canDeleteItem(props.item)" @confirmed="deleteItem(props.item.id)" />
						</div>
					</td>
				</tr>
			</template>
		</v-data-table>
	</section>
</template>

<script>
import { getHorario, mascaraDinheiro } from '@Util/functions';
import { PERIODO_DE_USO, PERIODO_DE_USO_LABEL } from '@Consts/posologia';
import PrescriptionReuseCycle from '@Componentes/medicamento/medicamento-card/aproveitamento-receita.vue';
import MedicamentoInfoIcons from '@Componentes/medicamento/medicamento-card/info-icons.vue';
import modalidadeAssinatura from '@Consts/modalidade_assinatura';
import moment from 'moment';
import EditItemDialog from './actions/edit-item-dialog';
import PauseItemDialog from './actions/pause-item-dialog/index.vue';
import DeleteItemDialog from './actions/delete-item-dialog/index.vue';

export default {
	name: 'ListProductionTable',
	components: {
		EditItemDialog,
		PauseItemDialog,
		DeleteItemDialog,
		PrescriptionReuseCycle,
		MedicamentoInfoIcons
	},
	props: {
		loading: {
			required: false,
			type: Boolean,
			default: false
		},
		items: {
			required: false,
			type: Array,
			default: () => ([])
		},
		total: {
			required: false,
			type: Number,
			default: 0
		},
		itemsPerPageOptions: {
			required: false,
			type: Array,
			default: () => ([5, 10, 25, 100])
		},
		itemsPerPage: {
			required: false,
			type: Number,
			default: 5
		},
		isPrescriptionCycleVisible: {
			required: false,
			type: Boolean,
			default: true
		},
		isPrescriptionReuseVisible: {
			required: false,
			type: Boolean,
			default: true
		},
		stock: {
			required: false,
			type: Array,
			default: () => ([])
		},
		initialDateToPause: {
			required: false
		},
		subscriptionMode: {
			type: String,
			required: false
		},
		selected: []
	},
	data() {
		return {
			generatingTags: false,
			boxId: null,
			dialogChoiceMedicinesOpened: false,
			pagination: {
				rowsPerPage: -1
			}
		};
	},
	computed: {
		tableItems() {
			return this.sortItems(this.items.map(med => {
				const packageQuantity = this.subscriptionIsIntegral ? med.qtd_mensal_sem_proporcionalidade : med.qtd_caixas;
				return {
					id: med.alteracao_id,
					medicineId: med.medicamento_id,
					name: med.medicamento.produto,
					presentation: med.medicamento.apresentacao,
					activeIngredient: med.medicamento.principio_ativo,
					type: med.medicamento.descricao_tipo_produto,
					isNew: !med.assinatura_medicamento_id,
					isPbm: !!med.medicamento.pbm,
					isDivisible: !med.medicamento.liquido,
					isControlled: !!med.medicamento.portaria_344_98,
					pause: med.pausa
						? {
							cycles: med.pausa.ciclos,
							reason: med.pausa.motivo
						} : null, // verificar data?
					posology: med.posologia,
					stock: med.estoque,
					typeOfPrescription: med.medicamento.portaria_344_98,
					price: med.preco_personalizado,
					discount: med.desconto,
					discountInCycle: med.desconto_no_ciclo || null,
					prescriptionCycles: med.ciclos_extras || 1,
					prescriptions: med.medicamento.receitas,
					createdAt: med.created_at,
					updatedAt: med.updated_at,
					deletedAt: med.deleted_at,
					packageQuantity: packageQuantity || null,
					quantityPills: med.qtd_mensal,
					medicamento: med,
					quantityPurchased: med.qtd_caixas_compradas
				};
			}));
		},
		headers() {
			return [
				{
					text: 'ID',
					value: 'header-id',
					sortField: 'id',
					sortable: true
				},
				{
					text: 'Medicamento',
					value: 'header-medicine',
					sortable: true,
					sortField: 'name',
					width: '300px'
				},
				{
					text: 'Princípio ativo',
					value: 'header-active-ingredient',
					sortable: true,
					sortField: 'activeIngredient'
				},
				{
					text: 'Tipo',
					value: 'header-medicine-type',
					sortable: true,
					sortField: 'type'
				},
				{
					text: 'Frequência',
					value: 'header-period-posology',
					sortable: false
				},
				{
					text: 'Posologia',
					value: 'header-posology',
					width: '10px',
					sortable: false
				},
				{
					text: 'Qtd. Caixas no Ciclo',
					value: 'header-packages-quantity',
					width: '10px',
					sortable: false,
					tooltip: true,
					tooltipText: 'Quantidade de caixas consumidas para o produto em questão de acordo com a posologia. Se o medicamento for não divisível, a quantidade de caixas será a consumida no ciclo.'
				},
				{
					text: 'Qtd. Caixas Compradas',
					value: 'header-packages-quantity',
					width: '10px',
					sortable: false,
					tooltip: true,
					tooltipText: 'Quantidade de caixas compradas envolvidas no ciclo em questão, contando caixas compradas com aproveitamento de receita.'
				},
				{
					text: 'Tipo de receita',
					value: 'header-prescription-type',
					sortable: false
				},
				{
					text: 'PBM',
					value: 'header-pbm',
					sortable: false
				},
				{
					text: 'Estoque',
					value: 'header-stock',
					sortable: false
				},
				{
					text: 'Valor unitário',
					value: 'header-amount',
					sortable: false
				},
				{
					text: 'Valor total',
					value: 'header-amount',
					sortable: false,
					tooltip: true,
					tooltipText: 'Valor que o cliente pagará no ciclo em questão referente aos medicamentos comprados para suprir sua assinatura.'
				},
				{
					text: 'Aprov. de receita',
					value: 'header-prescription-cycles',
					sortable: false,
					width: '10px'
				},
				{
					text: 'Info. adicionais',
					value: 'header-info',
					sortable: false
				},
				{
					text: 'Ações',
					sortable: false,
					width: '300px'
				}
			];
		},
		subscriptionIsIntegral() {
			return this.subscriptionMode === modalidadeAssinatura.INTEGRAL;
		}
	},
	methods: {
		sortItems(arrayItems) {
			const field = this.headers.find(h => h.value === this.pagination.sortBy)?.sortField;
			return arrayItems.sort((a, b) => {
				const valueA = a[field]?.toString().trim().toLowerCase() || '';
				const valueB = b[field]?.toString().trim().toLowerCase() || '';

				if (valueA < valueB)
					return this.pagination.descending ? 1 : -1;
				if (valueA > valueB)
					return this.pagination.descending ? -1 : 1;
				return 0;
			});
		},
		getStock(medicine) {
			if (!medicine.stock)
				return 0;
			if (medicine.isDivisible)
				return medicine.stock.comprimidos;
			return medicine.stock.caixas;
		},
		getBackgroundColorRow(item) {
			if (this.isDeleted(item))
				return '#E500001A';
			if (item.pause)
				return '#E2E2E2';
			if (item.isNew || (item.createdAt !== item.updatedAt))
				return '#DEEEFF';
			return 'white';
		},
		canEditItem(item) {
			return !this.isDeleted(item) && !this.isPaused(item);
		},
		canDeleteItem(item) {
			return !this.isDeleted(item);
		},
		canPauseItem(item) {
			return !this.isDeleted(item);
		},
		isActionEnabled(orignalItem) {
			return orignalItem.deleted_at === null;
		},
		isDeleted(item) {
			return !!item.deletedAt;
		},
		isPaused(item) {
			return !!item.pause;
		},
		isUpdated(item) {
			const createdAt = item.createdAt.toString();
			const updatedAt = item.updatedAt.toString();
			return createdAt !== updatedAt;
		},
		getOriginalItem(id) {
			return this.items.find(i => i.alteracao_id === id);
		},
		updateFilters() {
			this.$emit('filter', {
				pagination: {
					page: Number(this.pagination.page),
					perPage: Number(this.pagination.rowsPerPage)
				},
				sorting: {
					field: this.pagination.sortBy,
					direction: this.pagination.descending ? 'DESC' : 'ASC'
				}
			});
		},
		toggleAll() {
			if (this.selected.length)
				this.selected = [];
			else
				this.selected = this.items.slice();
		},
		changeSort(header) {
			if (!header.sortable)
				return;
			const column = header.value;
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		getDetailsFromPosologyFrequence(posology) {
			if (!posology)
				return null;
			const frequency = posology.periodo_de_uso;
			if (frequency === PERIODO_DE_USO.DIAS_DA_SEMANA) {
				const weekDays = posology.dias_da_semana;
				return weekDays.join(', ');
			}
			if (frequency === PERIODO_DE_USO.DIAS_DO_MES) {
				const monthDays = posology.dias_do_mes;
				return monthDays.join(', ');
			}
			if (frequency === PERIODO_DE_USO.OUTRO) {
				const type = posology.outro_periodo.tipo;
				const day = posology.outro_periodo.dia.toLowerCase();
				return `${type} ${day} do mês`;
			}
			return null;
		},
		getFrequencyPosology(posology) {
			if (posology.horarios.length > 0)
				return PERIODO_DE_USO_LABEL[posology.periodo_de_uso];

			return '-';
		},
		getItemsPosology(posology) {
			if (posology.horarios.length > 0)
				return posology.horarios.map(horario => getHorario(horario));

			return '-';
		},
		getIndividualPrice(item) {
			const price = (Number(item.price) - Number(item.discount));
			return price;
		},
		getFinalPrice(item) {
			const price = (Number(item.price) - Number(item.discount)) * item.quantityPurchased;
			if (item.quantityPurchased > 0) {
				if (this.isDeleted(item))
					return mascaraDinheiro(price);
				return mascaraDinheiro(price - Number(item.discountInCycle));
			}
			return mascaraDinheiro(0);
		},
		getAdditionalInfo(item) {
			let date;
			if (this.isDeleted(item)) {
				date = this.getDate(item.deletedAt);
				return `Excluído em ${date}`;
			}
			if (item.isNew) {
				date = this.getDate(item.createdAt);
				return `Adicionado em ${date}`;
			}
			if (this.isPaused(item)) {
				date = this.getDate(item.updatedAt);
				return `Pausado em ${date}`;
			}
			if (this.isUpdated(item)) {
				date = this.getDate(item.updatedAt);
				return `Editado em ${date}`;
			}
			return '-';
		},
		getPackageQuantity(packageQuantity) {
			if (packageQuantity)
				return `${packageQuantity} caixa${packageQuantity > 1 ? 's' : ''}`;
			return '-';
		},
		getPackageQuantityPurchased(quantityPurchased) {
			if (quantityPurchased)
				return `${quantityPurchased} caixa${quantityPurchased > 1 ? 's' : ''}`;
			return '-';
		},
		updatePrescriptionCycle(medicineId, cycles) {
			this.$emit('updatePrescriptionCycle', { medicineId, cycles });
		},
		deleteItem(draftId) {
			this.$emit('deleteItem', draftId);
		},
		pauseChanged() {
			this.$emit('pauseChanged');
		},
		editItem(data) {
			this.$emit('editItem', data);
		},
		getDate(itemDate) {
			// Diminui 3 horas para que produção consiga ver o horário correto
			const date = moment(itemDate).subtract(3, 'hours').format('DD/MM/YYYY HH:mm');
			return date;
		}
	},
	watch: {
		pagination: {
			deep: true,
			handler() {
				this.updateFilters();
			}
		},
		selected: {
			handler() {
				this.$emit('updatedSelected', this.selected);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

header {
	border-bottom-width: 5px;
	border-bottom-color: trans !important;
	border-bottom-style: solid;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.column-overflow {
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

::v-deep .custom-data-table {
	.v-table {
		background-color: transparent;
		border-collapse: separate;
		border-spacing: 0 5px;

		thead th {
			padding-top: 10px;
			padding-bottom: 10px;
			height: 100%;
		}

		thead {
			tr {
				background-color: transparent;
			}

			th {
				padding: 5px 10px;
				height: 100%;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					border: 2px solid transparent;
				}

				&:last-child {
					border: 4px solid transparent;
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}

		tbody {

			tr {
				background-color: transparent;
			}

			td {
				padding: 5px 10px;
				height: 100%;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					border: 2px solid inherit;
				}

				&:last-child {
					border: 4px solid inherit;
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}

	}

	.v-datatable {
		.v-datatable__actions {
			background-color: transparent;
		}
	}
}

.fixed-columns .v-data-table__wrapper {
  overflow-x: auto;
}

.fixed-columns th,
.fixed-columns td {
  position: relative;
}

.fixed-columns th:nth-child(1),
.fixed-columns td:nth-child(1) {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 1;
}

.fixed-columns th:nth-child(2),
.fixed-columns td:nth-child(2) {
  position: sticky;
  left: 54px;
  background-color: #fff;
  z-index: 1;
}

.fixed-columns th:nth-child(3),
.fixed-columns td:nth-child(3) {
  position: sticky;
  left: 104px;
  background-color: #fff;
  z-index: 1;
}

.total-value {
	font-weight: bold;
}
.active-ingredient p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 200px;
}
</style>
