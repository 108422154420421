<template>
	<section>
		<v-dialog
			v-model="opened"
			persistent
			fullscreen
			hide-overlay
			scrollable
			transition="fade">
			<transition>
				<PontualQuotation
					v-if="opened"
					title="Editar orçamento"
					:generalData="generalData"
					:productData="productData"
					:config="config"
					:allowNavagitation="true"
					@handleQuotation="editQuotation"
					@close="closeDialog" />
			</transition>
		</v-dialog>

		<LoaderDialog v-if="loading" />

	</section>

</template>

<script>
import LoaderDialog from '@Componentes/loader-dialog';
import PontualQuotation from '@Componentes/quotation-pontual/index.vue';
import moment from 'moment';
import { companyUnitAbbr, companyUnitIds } from '@Consts/unidades';
import { getUFByStateName } from '@Consts/estados';
import { RestHomePresenter } from '@Presenter/v2/rest-home-presenter';
import { PersonPresenter } from '@Presenter/v2/person-presenter';
import { PatientPresenter } from '@Presenter/v2/patient-presenter';
import { PontualQuotationUtil } from '@Util/pontual-quotation';
import { PontualQuotationPresenter } from '@Presenter/v2/pontual-quotation-presenter';
import { QuotationPresenter } from '@Presenter/quotation-presenter';

export default {
	name: 'PontualQuotationUpdate',
	components: {
		PontualQuotation,
		LoaderDialog
	},
	async created() {
		this.loading = true;

		await this.init();
	},
	data() {
		return {
			opened: false,
			loading: false,
			quotation: {},
			generalData: {},
			productData: {},
			config: {
				showCustomerSearch: false,
				showCustomerDetail: true,
				canChangeCustomer: false,
				showRestHomeSearch: false,
				showRestHomeSection: false,
				canChangeRestHome: false,
				showAddressSection: true,
				canMarkAsOptionalPayment: false,
				canChangeEmail: false,
				showProcessingUnitDetail: false
			}
		};
	},
	methods: {
		async init() {
			this.loading = true;
			try {
				const quotationId = this.$route.params.id;
				const response = await PontualQuotationPresenter.getDetail(quotationId);
				if (!PontualQuotationUtil.isDraft(response.status)) {
					this.$router.push({ name: 'quotation.pontual.list' });
					return this.$store.dispatch(
						'SHOW_SNACKBAR',
						{
							message: 'Esse orçanemtno não pode ser editado',
							color: 'error'
						}
					);
				}

				this.quotation = response;

				this.initGeneralData();
				this.initProductData();

				if (this.quotation.customer.externalId)
					await this.getCustomer(this.quotation.customer.externalId);

				if (this.quotation.restHomeId) {
					await this.getRestHome(this.quotation.restHomeId);
					this.config.showRestHomeSection = true;
				}

				this.openDialog();
			} catch (e) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						message: e.message || 'Erro ao buscar orçamento pontual.',
						color: 'error'
					}
				);
			} finally {
				this.loading = false;
			}
		},

		openDialog() {
			this.opened = true;
		},

		closeDialog() {
			this.opened = false;
			this.$router.push({ name: 'quotation.pontual.list' });
		},

		initGeneralData() {
			this.generalData = {
				address: {
					zipCode: this.quotation.address.zipCode,
					street: this.quotation.address.street,
					number: this.quotation.address.addressNumber,
					neighborhood: this.quotation.address.neighborhood,
					city: this.quotation.address.city,
					state: this.quotation.address.state.length > 2 ? getUFByStateName(this.quotation.address.state) : this.quotation.address.state,
					complement: this.quotation.address.complement
				},
				customer: {
					externalId: this.quotation.customer.externalId,
					name: this.quotation.customer.name,
					documentNumber: this.quotation.customer.cpf,
					phone: this.quotation.customer.phone,
					email: this.quotation.customer.email,
					gender: this.quotation.customer.gender,
					birthdate: this.$functions.formatDate(this.quotation.customer.birthdate, 'DD/MM/YYYY') // verificar conversão
				},
				extra: {
					seller: {
						id: this.quotation.seller.id,
						name: this.quotation.seller.name || null,
						phone: this.quotation.seller.phone || null
					},
					expiresAt: this.$functions.formatDate(this.quotation.expiresAt, 'DD/MM/YYYY'), // verificar parse
					companyUnitId: companyUnitIds[this.quotation.companyUnit],
					person: null,
					restHome: null,
					observation: this.quotation.publicObservations || null,
					isOptionalPayment: PontualQuotationUtil.isB2B2C(this.quotation.businessType),
					businessType: PontualQuotationUtil.getQuotationBusinessTypeFromQuotationType(this.quotation.type)

				}
			};
		},

		initProductData() {
			this.productData = {
				shippingPrice: this.quotation.shippingPrice,
				deliveryDate: this.$functions.formatDate(this.quotation.startDate, 'DD/MM/YYYY'),
				items: this.quotation.items.map(item => ({
					...item,
					pricing: {
						packagePrice: item.priceSku,
						packageDiscount: item.discount,
						packageFinalPrice: item.priceSku - item.discount,
						packageAmount: item.closedPackages
					}

				}))
			};
		},

		async editQuotation(data) {
			this.loading = true;
			const { generalData, productData } = data;

			const quotation = {
				type: PontualQuotationUtil.getQuotationTypeFromBusinessType(generalData.extra.businessType),
				publicId: this.quotation.publicId,
				companyUnit: companyUnitAbbr[generalData.extra.companyUnitId],
				restHomeId: PontualQuotationUtil.isB2C(generalData.extra.businessType) ? null : generalData.extra.restHome.id,
				startDate: this.$functions.FormatDateToRequest(this.productData.deliveryDate),
				shippingPrice: this.$functions.onlyNumber(this.productData.shippingPrice),
				expiresAt: moment(this.$functions.FormatDateToRequest(generalData.extra.expiresAt)).format(),
				percentDiscountFirstBox: 0,
				publicObservations: generalData.extra.observation,
				customer: {
					externalId: this.generalData.customer.externalId,
					cpf: this.generalData.customer.documentNumber,
					name: this.generalData.customer.name,
					gender: this.generalData.customer.gender,
					birthdate: this.$functions.FormatDateToRequest(this.generalData.customer.birthdate),
					email: generalData.customer.email,
					phone: generalData.customer.phone
				},
				address: {
					zipCode: generalData.address.zipCode,
					street: generalData.address.street,
					addressNumber: generalData.address.number,
					neighborhood: generalData.address.neighborhood,
					city: generalData.address.city,
					state: generalData.address.state, // deve ser a sigla do estado
					stateName: getUFByStateName(generalData.address.state),
					complement: generalData.address.complement
				},
				seller: {
					name: generalData.extra.seller.name,
					phone: generalData.extra.seller.phone
				},
				items: productData.items.map(prodt => {
					const price = (prodt.pricing.packagePrice - prodt.pricing.packageDiscount) * prodt.pricing.packageAmount;
					return {
						productId: prodt.productId,
						name: prodt.name,
						presentation: prodt.presentation,
						dosage: prodt.dosage,
						isControlled: prodt.isControlled,
						isDivisible: prodt.isDivisible,
						gtin: prodt.gtin,
						manufacturer: prodt.manufacturer,
						isOutOfBox: prodt.isOutOfBox,
						hasPrescriptionUtilization: prodt.hasPrescriptionUtilization,
						closedPackages: prodt.pricing.packageAmount,
						quantityPillsPerPackage: prodt.quantityPillsPerPackage,
						priceSku: prodt.pricing.packagePrice,
						discount: prodt.pricing.packageDiscount,
						price
					};
				})
			};

			const quotationId = this.$route.params.id;

			try {
				await QuotationPresenter.update(quotationId, quotation);
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'success',
					message: 'Orçamento atualizado com sucesso!'
				});
				this.closeDialog();
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						message: error.message || 'Erro ao editar orçamento pontual.',
						color: 'error'
					}
				);
			} finally {
				this.loading = false;
			}
		},

		async getRestHome(restHomeId) {
			try {
				const restHomeDetailResponse = await RestHomePresenter.getDetail(restHomeId);
				this.generalData.extra.restHome = restHomeDetailResponse;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Erro ao buscar a ILPI ou convênio'
				});
			}
		},

		async getCustomer(customerId) {
			await this.getPerson(customerId);
			await this.getPatient(customerId);
		},

		async getPerson(id) {
			try {
				const personData = await PersonPresenter.getDetails(id);
				this.generalData.extra.person = personData;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Erro ao buscar dados da pessoa'
				});
			}
		},

		async getPatient(id) {
			try {
				const patientData = await PatientPresenter.find(id);
				this.generalData.extra.person = patientData;
				if (!this.generalData.extra.restHome && this.generalData.extra.person.restHome)
					this.generalData.extra.restHome = this.generalData.extra.person.restHome;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'warning',
					textColor: 'rgba(0, 0, 0, 0.87)',
					timeout: 10000,
					message: 'O cliente selecionado não possui uma assinatura Far.me ativa.'
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
