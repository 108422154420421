import $http from '@Config/axios';

const PREFIX_V2 = '/public/settings';

export class SettingsPresenter {
	/**
	 * @returns {Promise<SettingItem>}
	 */
	static async getShippingFee() {
		const response = await $http.get(`${PREFIX_V2}/shipping-fee`);
		return {
			name: response.name,
			description: response.description,
			limit: response.limit,
			value: response.value
		};
	}
}

/**
 * @typedef {Object} SettingItem
 * @property {String} name
 * @property {String} description
 * @property {String | Number} limit
 * @property {String | Number} value
 */
