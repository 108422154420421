import * as types from './mutation-types';

export const CLEAR_STATE = ({ commit }) => commit(types.CLEAR_STATE);
export const CONTEXTO = ({ commit }, contexto) => commit(types.CONTEXTO, contexto);
export const NOVABOX = ({ commit }, novabox) => commit(types.NOVABOX, novabox);
export const SHOW_SNACKBAR = ({ commit }, config) => commit(types.SNACKBAR, config);
export const HIDE_SNACKBAR = ({ commit }) => commit(types.SNACKBAR, { visible: false });
export const SET_VISAO_COLABORADOR = ({ commit }, bool) => commit(types.SET_VISAO_COLABORADOR, bool);
export const MSG_TOAST = ({ commit }, mensagem) => commit(types.MSG_TOAST, mensagem);
export const FETCH_FEATURE_FLAGS = ({ commit }, unleash) => {
	commit('SET_FEATURE_FLAGS', unleash);
};
export const FETCH_PERMISSIONS = ({ commit }, unleash) => {
	commit('SET_PERMISSIONS', unleash);
};
