<template>
	<v-chip
		label
		color="secondary"
		text-color="white"
		class="py-1" style="border-radius: 10px;">
		<v-icon class="px-2">swap_horiz</v-icon>
	</v-chip>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
