// roles
export const permissoes = {
	Vendedor: 5,
	Operador: 10,
	Financeiro: 11,
	RT: 12,
	Clinica: 13,
	Administrador: 20,
	Farmeos: 99
};

export const permissions = Object.freeze({
	delete_box: 'FARMEBOX_PERMISSION_DELETE_BOX',
	change_shipping_fee: 'FARMEBOX_PERMISSION_CHANGE_SHIPPING_FEE',
	approve_pontual_quotation: 'FARMEBOX_PERMISSION_APPROVE_PONTUAL_QUOTATION',
	enable_duplicate_with_bank_slip: 'FARMEBOX_PERMISSION_ENABLE_DUPLICATE_WITH_BANK_SLIP'
});
