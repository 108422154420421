<template>
	<Dialog
		titulo="Atenção"
		subtitulo="Ao realizar essa ação, estou ciente da liberação"
		:btn_acao="'Confirmar'"
		@acao="$emit('confirm', false);"
		@close="$emit('close', false);">

		<v-container fluid grid-list-lg class="pa-0">
			Ao clicar em confirmar, a box {{boxId}} será liberada para pré-produção. <br/><br/>

			Confirma que todas as alterações necessárias foram realizadas? <br/>

		</v-container>
	</Dialog>
</template>

<script>

export default {
	name: 'ConfirmManualInvoicingDialog',
	props: {
		boxId: {
			type: Number,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
