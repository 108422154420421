<template>
	<header class="q-row">
		<div class="items-center space-between q-row">
			<h1 class="text--text mr-5" style="opacity: 0.8; font-size: 35px">
				Cobrança
			</h1>

			<v-tabs
				color="transparent"
				v-model="filter.view"
				light
				slider-color="primary">
				<v-tab
					v-for="item in totalResume"
					:key="item.key"
					ripple>
					{{ item.name }} <v-chip small color="primary" class="ml-3">{{ item.total }}</v-chip>
				</v-tab>
			</v-tabs>
		</div>

		<div class="items-center space-between q-row">
			<v-text-field
				v-model="filter.search"
				label="Busca"
				placeholder="Digite o ID da box ou assinatura..."
				class="mr-4"
				hide-details
				append-icon="search" />

			<v-menu
				style="height: 100%;"
				:close-on-content-click="false">
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						color="secondary"
						class="px-4 py-4 ma-0 items-center space-between"
						outline
						style="border-radius: 10px;">
						<span>Filtros</span>
						<v-icon small class="ml-5">keyboard_arrow_down</v-icon>
						<v-icon v-if="hasFilterOn" style="position: absolute; top: -25px;right:-32px" color="orange" small>fas fa-exclamation-circle</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-tile>
						<v-list-tile-title>Filtros</v-list-tile-title>
					</v-list-tile>

					<v-divider></v-divider>
					<v-list-tile>
						<v-list-tile-sub-title>Data de inicio da box entre:</v-list-tile-sub-title>
					</v-list-tile>

					<v-list-tile class="py-3">
						<TextDateField v-model="filter.initialDateRange"
							label="Inicio"
							placeholder="dd/mm/yyyy"
							:validate="{ required: false, data: true }"
							:flat="true" />
					</v-list-tile>

					<v-list-tile class="py-3">
						<TextDateField v-model="filter.finalDateRange"
							label="Fim"
							placeholder="dd/mm/yyyy"
							:validate="{ required: false, data: true }"
							:flat="true" />
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-2">
						<v-autocomplete
							v-model="filter.restHomeIds"
							full-width
							background-color="white"
							:loading="restHome.loading"
							:disabled="restHome.loading"
							:items="restHome.items"
							label="Casas de repouso"
							no-data-text="Nenhuma casa de repouso"
							box
							hide-details
							clearable
							item-value="id"
							item-text="nome"
							multiple
							small-chips />
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-2">
						<v-autocomplete
							v-model="filter.boxStatus"
							box
							full-width
							hide-details
							background-color="white"
							:items="availableStatusToFilter"
							item-value="value"
							item-text="label"
							label="Status da box"
							v-validate="{ required: false }"/>
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-2">
						<v-autocomplete
							v-model="filter.paymentMode"
							box
							full-width
							hide-details
							background-color="white"
							:items="[
								{ text: 'Todos', value: null },
								{ text: 'Proporcional', value: 'proporcional' },
								{ text: 'Integral', value: 'integral' }
							]"
							item-value="value"
							item-text="text"
							no-data-text="Nenhum tipo disponível"
							label="Modo de pagamento"
							v-validate="{ required: false }"/>
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-2">
						<v-autocomplete
							v-model="filter.withPBM"
							box
							full-width
							hide-details
							background-color="white"
							:items="[
								{ text: 'Todos', value: null },
								{ text: 'Cobrança com PBM', value: true },
								{ text: 'Cobrança sem PBM', value: false }
							]"
							item-value="value"
							item-text="text"
							label="Cobrança com/sem PBM"
							v-validate="{ required: false }"/>
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-2">
						<v-combobox
							v-model="filter.ignoreTags"
							background-color="white"
							full-width
							:loading="tags.loading"
							:disabled="tags.loading"
							:items="tags.names"
							label="Não listar tags"
							no-data-text="Nenhuma tag encontrada"
							hide-details
							clearable
							multiple
							small-chips>
							<template v-slot:selection="data">
								<v-chip
									close
									@input="removeTag(data.item)"
									class="pointer">
									<strong>{{ data.item }}</strong>
								</v-chip>
							</template>
						</v-combobox>
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-2">
						<v-checkbox v-model="filter.showDeletedSubscriptions" label="Exibir assinaturas canceladas" />
					</v-list-tile>

				</v-list>
			</v-menu>

		</div>
	</header>
</template>

<script>
import moment from 'moment';
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import { TagPresenter } from '@Presenter/tag-presenter';
import { boxStatusBoxMap, getStatusBox } from '@Consts/status-box';

export default {
	name: 'ListChargesHeader',
	props: {
		availableViews: {
			required: true,
			type: Object
		},
		total: {
			required: false,
			type: Number,
			default: 0
		},
		totalCharged: {
			required: false,
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			boxStatusBoxMap,
			filter: {
				view: null,
				search: null,
				initialDateRange: moment().subtract(30, 'days').format('DD/MM/YYYY'),
				finalDateRange: moment().add(10, 'days').format('DD/MM/YYYY'),
				restHomeIds: null,
				paymentMode: null,
				withPBM: null,
				boxStatus: null,
				ignoreTags: [],
				showDeletedSubscriptions: false
			},
			restHome: {
				items: [],
				loading: false
			},
			tags: {
				items: [],
				names: [],
				loading: false
			},
			availableStatusToFilter: [],
			loading: false
		};
	},
	async created() {
		await this.getRestHomes();
		await this.getTags();
		this.availableStatusToFilter = [
			{ label: 'Todos', value: null },
			...Object.keys(boxStatusBoxMap).map(s => ({
				label: getStatusBox(s),
				value: s
			}))];
	},
	computed: {
		hasFilterOn() {
			if (this.filter.search || this.filter.restHomeIds || this.filter.paymentMode || this.filter.boxStatus || this.filter.ignoreTags.length > 0)
				return true;

			if (this.filter.withPBM !== null)
				return true;

			if (this.filter.showDeletedSubscriptions)
				return true;

			if (this.filter.initialDateRange !== moment().subtract(90, 'days').format('DD/MM/YYYY'))
				return true;

			if (this.filter.finalDateRange !== moment().format('DD/MM/YYYY'))
				return true;
			return false;
		},
		totalResume() {
			return [
				{
					key: 'NOT_CHARGED',
					name: this.availableViews.NOT_CHARGED,
					total: this.total
				},
				{
					key: 'CHARGED',
					name: this.availableViews.CHARGED,
					total: this.totalCharged
				}
			];
		}
	},
	methods: {
		handleFilter() {
			this.$emit('filter', {
				filter: {
					...this.filter,
					ignoreTagsIds: this.tags.items.filter(item => this.filter.ignoreTags.includes(item.nome)).map(i => i.id),
					initialDateRange: this.$functions.FormatDateToRequest(this.filter.initialDateRange),
					finalDateRange: this.$functions.FormatDateToRequest(this.filter.finalDateRange),
					view: this.totalResume[this.filter.view].key
				}
			});
		},
		async getRestHomes() {
			this.restHome.loading = true;
			try {
				const restHomeResponse = await CasaRepousoPresenter.index();
				this.restHome.items = restHomeResponse;
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro ao carregar casas de repouso.', color: 'error' }
				);
			} finally {
				this.restHome.loading = false;
			}
		},
		async getTags() {
			this.tags.loading = true;
			try {
				const tags = await TagPresenter.index();
				this.tags.items = (tags || []).filter(tag => tag.nome.includes('GLOBAL_'));
				this.tags.names = this.tags.items.map(i => i.nome);
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro ao carregar tags', color: 'error' }
				);
			} finally {
				this.tags.loading = false;
			}
		},
		removeTag(tagNameToRemove) {
			const tagToRemove = this.tags.items.find(item => item.nome === tagNameToRemove);
			const indexOfItemToRemove = this.filter.ignoreTags.findIndex(name => name === tagToRemove.nome);
			this.filter.ignoreTags.splice(indexOfItemToRemove, 1);
		}
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.handleFilter();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
header {
	border-bottom-width: 5px;
	border-bottom-color: #ffd9a0 !important;
	border-bottom-style: solid;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
