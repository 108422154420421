<template>
	<Dialog :titulo="'Novo ' + getTitle" btn_acao="Salvar" :btn_acao_disable="false"
		@acao="submeter" @close="closeDialog">
		<v-container fluid grid-list-lg class="px-3 pt-1">

			<v-layout wrap>
				<v-flex xs12 sm6 v-show="showPersonalFields">
					<v-text-field label="CPF" name:="cpf" :data-vv-name="'cpf'" :data-vv-scope="null"
						v-model="form.cpf" :error-messages="errors.first('cpf')" :clearable="true"
						v-validate="{ required: showPersonalFields, cpf: showPersonalFields }" v-mask="['###.###.###-##']" class="error-2-lines"
						:color="'green'" box :loading="searching" @change="searchResponsavel"
						@click:clear="resertForm" />
				</v-flex>

				<v-flex xs12 sm6 d-flex>
					<v-select label="Vínculo com o farmembro" :data-vv-name="'vinculo'" :data-vv-scope="null"
						v-model="form.vinculo" :error-messages="errors.first('vinculo')" :clearable="true"
						v-validate="{ required: true }" :items="vinculos" box />
				</v-flex>

				<v-flex xs12 v-if="showPersonalFields">
					<v-text-field label="Nome Completo" :data-vv-name="'nome'" :data-vv-scope="null"
						v-model="form.nome" :error-messages="errors.first('nome')"
						v-validate="{ required: showPersonalFields }" :disabled="disabled" :color="'green'" box></v-text-field>
				</v-flex>

				<v-flex xs12 sm6 v-if="showPersonalFields">
					<v-text-field
						box
						label="E-mail"
						v-model="form.email"
						v-validate="{
							required: showPersonalFields,
							email: showPersonalFields,
							regex: emailRegex
						}"
						:data-vv-name="'email'"
						:data-vv-scope="null"
						:error-messages="errors.first('email')"
						:disabled="disabled"
						:color="'green'">
					</v-text-field>
				</v-flex>

				<v-flex xs12 sm6 v-if="showPersonalFields">
					<v-text-field
						label="Telefone"
						box
						v-model="form.telefone"
						v-mask="['(##) #####-####', '(##) ####-####']"
						v-validate="{
							telefone: showPersonalFields,
							required: showPersonalFields,
							regex: phoneRegex
						}"
						:data-vv-name="'telefone'"
						:data-vv-scope="null"
						:error-messages="errors.first('telefone')"
						:disabled="disabled"
						:color="'green'">
					</v-text-field>
				</v-flex>

				<v-flex xs12 sm6 v-if="restHome">
					<p><b>ILPI:</b> {{ restHome.nome }}</p>
					<p><b>Responsável:</b> {{ restHome.nome_responsavel }}</p>
					<p><b>CNPJ:</b> {{ restHome.cnpj }}</p>
					<p><b>Endereço:</b> {{ restHome.logradouro }} {{ restHome.numero }}
						{{ restHome.cidade}} - {{ restHome.estado }}</p>
					<p><b>Telefone do responsável:</b> {{ restHome.telefone_responsavel }}</p>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { ResponsavelPresenter } from '@Presenter/responsavel-presenter';
import { onlyNumber, tiposResponsaveis, isDomicilio } from '@Util/functions';
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import { RESPONSIBLE_PAYMENT_REST_HOME } from '@Consts/rest-home/responsible-payment';
import { GeneralFieldsUtil } from '@Util/general-fields';

export default {
	name: 'DialogNovoResponsavel',
	inject: ['$validator'],
	emits: ['close', 'inserir'],
	props: {
		pacientId: {
			required: true,
			type: Number,
			default: 0
		},
		restHomeId: {
			type: Number,
			required: false
		},
		tipo: {
			required: true,
			type: String,
			validator(value) {
				return Object.keys(tiposResponsaveis).includes(value);
			}
		},
		patientResponsibleFromQuotation: {
			type: Object,
			required: false,
			default: null
		},
		patientResponsibles: {
			required: false,
			default: null
		}
	},
	data: () => ({
		form: {
			cpf: null,
			nome: null,
			vinculo: null,
			email: null,
			telefone: null
		},
		responsavel_id: null,
		searching: false,
		disabled: true,
		vinculos: ['Pai', 'Mãe', 'Filho(a)', 'Cuidador(a)', 'Enfermeiro(a)', 'Tio(a)', 'Avô(ó)', 'Sobrinho(a)', 'Irmão(ã)', 'Neto(a)', 'ILPI', 'Outros'],
		restHome: null,
		phoneRegex: GeneralFieldsUtil.phoneRegexValidationWithMask(),
		emailRegex: GeneralFieldsUtil.emailRegexValidation()
	}),
	created() {
		if (this.patientResponsibleFromQuotation)
			this.form = this.patientResponsibleFromQuotation;
	},
	watch: {
		showPersonalFields(val) {
			if (!val && this.restHomeId)
				this.searchRestHome();
			else
				this.restHome = null;
		}
	},
	computed: {
		getTitle() {
			return tiposResponsaveis[this.tipo]?.descricao ?? 'Responsável com tipo desconhecido';
		},
		showPersonalFields() {
			return this.form.vinculo !== RESPONSIBLE_PAYMENT_REST_HOME.value;
		}
	},
	methods: {
		closeDialog() {
			this.$emit('close', false);
		},
		resertForm() {
			Object.keys(this.form)
				.forEach(key => {
					this.form[key] = null;
				});

			this.$nextTick(() => {
				this.errors.clear();
				this.$nextTick(() => {
					this.$validator.reset();
				});
			});

			this.disabled = true;
		},
		async searchRestHome() {
			try {
				this.restHome = await CasaRepousoPresenter.show(this.restHomeId);
				if (isDomicilio(this.restHome?.nome)) {
					this.form.vinculo = null;
					this.restHome = null;
					this.$store.dispatch('SHOW_SNACKBAR',
						{ color: 'error', message: 'Não é possível definir ILPI domicílio como responsável' });
				}
			} catch (err) {
				this.form.vinculo = null;
			}
		},
		async searchResponsavel() {
			if (!await this.$validator.validate('cpf', this.form.cpf))
				return;

			const cpf = onlyNumber(this.form.cpf);
			try {
				const { itens } = await ResponsavelPresenter.list({
					cpf,
					q: cpf,
					itens_por_pagina: 1
				});

				if (itens.length === 0) {
					this.resertForm();
					this.disabled = false;
					this.form.cpf = cpf;
					this.responsavel_id = null;
				}

				if (itens.length === 1) {
					const responsavelEncontrado = itens[0];

					this.responsavel_id = responsavelEncontrado.id;

					this.form.cpf = responsavelEncontrado.cpf;
					this.form.email = responsavelEncontrado.email;
					this.form.telefone = responsavelEncontrado.telefone;
					this.form.nome = responsavelEncontrado.nome;
				}
			} finally {
				this.searching = false;
			}
		},
		async submeter() {
			if (!this.restHome && !await this.$validator.validateAll()) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
				return;
			}
			try {
				const data = {
					...this.form,
					id: this.responsavel_id ?? undefined,
					paciente_id: this.pacientId,
					tipo: Object.keys(tiposResponsaveis).reduce((a, v) => ({ ...a, [v]: v === this.tipo }), {}),
					casa_repouso_id: this.restHome?.id
				};

				const $http = data.id ? ResponsavelPresenter.updateResponsavelFarmembro : ResponsavelPresenter.store;
				const response = await $http(data);
				this.$emit('inserir', response);
				this.closeDialog();
			} finally {
				this.salvando = false;
			}
		}
	}
};
</script>
