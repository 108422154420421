<template>
	<v-container fluid grid-list-md class="pa-0">
		<v-layout wrap>
			<CompanyUnitAndRestHomeSection
				ref="companyUnitInfo"
				:extra="generalData.extra"/>

			<FarmeDivider />

			<v-flex  xs12 class="d-flex items-center justify-start">
				<h3 class="body-1 inter font-weight-bold mb-2">
					Cliente - Dados pessoais
				</h3>
			</v-flex>

			<v-flex xs12 >
				<InputSearchCustomer
					v-if="config.showCustomerSearch"
					@handlePatientSearch="onHandlePatientSearch" class="mb-4" />
				<CustomerInfo
					v-if="config.showCustomerDetail"
					ref="customerInfo"
					:customer="generalData.customer"
					:config="config"
					:extra="generalData.extra"
					@resetCustomer="onResetCustomer"/>
			</v-flex>

			<FarmeDivider />

			<template  v-if="!config.showCustomerSearch && config.canChangeCustomer">
				<ProcessingUnit ref="processingUnit" :extra="generalData.extra" />
				<FarmeDivider />
			</template>

			<template v-if="config.showRestHomeSection">

				<v-flex  xs12 class="d-flex items-center justify-start">
					<h3 class="body-1 inter font-weight-bold mb-2">
						ILPI ou Convênio - Dados gerais
					</h3>
				</v-flex>

				<v-flex xs12 v-if="config.showRestHomeSearch">
					<InputSearchRestHome class="mb-4"
						@handleRestHomeSearch="onHandleRestHomeSearch"
						:companyUnitId="generalData.extra.companyUnitId" />
				</v-flex>

				<template v-else>
					<RestHomeInfo
						v-if="generalData.extra.restHome"
						:extra="generalData.extra"
						:config="config"
						@resetRestHome="onResetRestHome"/>

				</template>
				<FarmeDivider />

			</template>

			<template v-if="config.showAddressSection">

				<DeliveryAddressSection
					ref="addressInfo"
					:address="generalData.address"
					@addressUpdated="data => setCompanyUnitIdWhenAddressUpdated(data)"/>

				<FarmeDivider />

			</template>

			<SellerExpiresDate
				ref="sellerInfo"
				:extra="generalData.extra" />

			<FarmeDivider />
		</v-layout>
	</v-container>
</template>

<script>

import { sexByValue } from '@Consts/sexo';
import FarmeDivider from '@Componentes/farme-divider.vue';
import { BUSINESS_B2B_TYPE, BUSINESS_B2C_TYPE } from '@Consts/business-type';
import { companyUnitIds } from '@Consts/unidades';
import DeliveryAddressSection from './delivery-address/index.vue';
import CompanyUnitAndRestHomeSection from './company-unit-rest-home.vue';
import SellerExpiresDate from './seller-expires-date.vue';
import InputSearchCustomer from './search-customer.vue';
import InputSearchRestHome from './search-rest-home.vue';
import CustomerInfo from './personal-data/customer-info.vue';
import RestHomeInfo from './rest-home-data/rest-home-info.vue';
import ProcessingUnit from './unit-process.vue';

export default {
	name: 'GeneralDataPontualQuotation',
	inject: ['$validator'],
	components: {
		FarmeDivider,
		CompanyUnitAndRestHomeSection,
		InputSearchRestHome,
		DeliveryAddressSection,
		SellerExpiresDate,
		CustomerInfo,
		ProcessingUnit,
		RestHomeInfo,
		InputSearchCustomer
	},
	props: {
		generalData: {
			required: true,
			type: Object,
			default: () => {}
		},
		config: {
			required: true,
			type: Object
		}
	},
	methods: {
		onHandlePatientSearch(data) {
			if (!data)
				return;

			this.setCustomer(data);
			this.setAddress(data);
			this.setRestHomeData(data.restHome);
			this.setExtra(data);
		},

		onHandleRestHomeSearch(data) {
			this.setRestHomeData(data);
			this.config.canChangeRestHome = true;
			this.config.showRestHomeSection = true;
		},

		onResetRestHome() {
			this.config.showRestHomeSearch = true;
			this.resetRestHomeData();
		},

		onResetCustomer() {
			this.resetCustomerData();
			this.resetAddressData();
			this.resetExtraData();
			this.resetRestHomeData();
			this.config.showCustomerSearch = true;
			this.config.showCustomerDetail = false;
			this.config.showRestHomeSearch = false;
			this.config.showRestHomeSection = false;
			this.config.showAddressSection = false;
		},

		setCustomer(data) {
			this.resetCustomerData();
			this.generalData.customer.externalId = data.id;
			this.generalData.customer.gender = !data.sex ? null : sexByValue[data.sex];
			this.generalData.customer.birthdate = !data.birthdate ? null : this.$functions.formatDate(data.birthdate, 'DD/MM/YYYY');
			this.generalData.customer.name = data.name;
			this.generalData.customer.documentNumber = data.document;
			this.generalData.customer.phone = data.phone;
			this.generalData.customer.email = data.email;
			this.config.showCustomerSearch = false;
			this.config.showCustomerDetail = true;
		},

		resetCustomerData() {
			this.generalData.customer.externalId = null;
			this.generalData.customer.gender = null;
			this.generalData.customer.birthdate = null;
			this.generalData.customer.name = null;
			this.generalData.customer.documentNumber = null;
			this.generalData.customer.phone = null;
			this.generalData.customer.email = null;
		},

		setAddress(data) {
			this.config.showAddressSection = true;
			this.resetAddressData();
			if (!data)
				return;

			let address = null;

			if (data.restHome && data.restHome.address)
				address = data.restHome.address;

			if (data.subscription && data.subscription.address)
				address = data.subscription.address;

			if (!address)
				return;

			this.generalData.address.zipCode = address.zipcode;
			this.generalData.address.street = address.street;
			this.generalData.address.number = address.number;
			this.generalData.address.neighborhood = address.neighborhood;
			this.generalData.address.complement = address.complement;
			this.generalData.address.city = address.city;
			this.generalData.address.state = address.state;
		},

		resetAddressData() {
			this.generalData.address.zipCode = null;
			this.generalData.address.street = null;
			this.generalData.address.number = null;
			this.generalData.address.neighborhood = null;
			this.generalData.address.complement = null;
			this.generalData.address.city = null;
			this.generalData.address.state = null;
		},

		setExtra(data) {
			this.resetExtraData();
			if (!data)
				return;
			this.generalData.extra.person = data;
			if (this.generalData.extra.person && this.generalData.extra.person.subscription) {
				this.generalData.extra.companyUnitId = this.generalData.extra.person.subscription.companyUnitId;
				this.generalData.extra.businessType = this.generalData.extra.person.type;

				if (this.generalData.extra.person.subscription.externalCharge)
					this.generalData.extra.isOptionalPayment = false;
				else
					this.generalData.extra.isOptionalPayment = true;
			}
		},

		resetExtraData() {
			this.generalData.extra.person = null;
			this.generalData.extra.orderProcessingUnitId = null;
		},

		setRestHomeData(data) {
			this.resetRestHomeData();
			this.config.showRestHomeSection = true;

			if (!data) {
				this.config.showRestHomeSearch = true;
				this.generalData.extra.businessType = BUSINESS_B2C_TYPE.value;
				return;
			}
			this.config.showRestHomeSearch = false;

			this.config.canChangeRestHome = !(this.$route.name === 'quotation.pontual.update');
			this.generalData.extra.restHome = data;

			if (data.businessType === BUSINESS_B2B_TYPE.value) {
				this.generalData.extra.businessType = BUSINESS_B2B_TYPE.value;
				this.config.canChangeRestHome = false;
			}

			if (data.businessType === BUSINESS_B2C_TYPE.value) {
				this.generalData.extra.businessType = BUSINESS_B2C_TYPE.value;
				this.config.showRestHomeSearch = false;
				this.config.canChangeRestHome = false;
				this.config.showRestHomeSection = false;
			} else if (data.address && !this.generalData.address.zipCode)
				this.setAddress({ restHome: { address: data.address, state: data.address.state } });
		},

		resetRestHomeData() {
			this.generalData.extra.restHome = null;
			this.generalData.extra.isOptionalPayment = false;
			this.generalData.extra.businessType = this.generalData.extra.person ? this.generalData.extra.person.type : null;
		},

		setCompanyUnitIdWhenAddressUpdated(address) {
			if (this.generalData.extra.companyUnitId)
				return;

			this.generalData.extra.companyUnitId = address.state === 'MG'
				? companyUnitIds.BH
				: companyUnitIds.SP;
		},

		async validate() {
			if (!this.config.showCustomerDetail)
				return false;
			const [
				isPersonalDataValid,
				isAddressDataValid,
				isSellerValid
			] = await Promise.all([
				this.isCustomerValid(),
				this.isAddressValid(),
				this.isSellerInfoValid()
			]);

			return !!isPersonalDataValid && !!isAddressDataValid && !!isSellerValid;
		},

		async isCustomerValid() {
			const isValid = await this.$refs.customerInfo.validate();
			return !!isValid;
		},

		async isAddressValid() {
			const isValid = await this.$refs.addressInfo.validate();
			return !!isValid;
		},

		async isSellerInfoValid() {
			const isValid = await this.$refs.sellerInfo.validate();
			return !!isValid;
		}
	}
};
</script>
