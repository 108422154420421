import $http from '@Config/axios';

const prefix = '/v2/etiquetas';
/**
 *
 * @param {String} tag
 * @returns {Promise<GetTagResponse>}
 */
async function get(tag) {
	return $http.get(`${prefix}/${tag}`);
}

export const EtiquetaPresenter = {
	get
};
