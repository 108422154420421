<template>
	<Dialog
		titulo="Alteração de medicamento"
		subtitulo="Ao realizar essa ação, o medicamento será alterado na box"
		btn_acao_extra_2="Voltar"
		:btn_acao_extra_2_disable="stepper <= 1"
		@acao_extra_2="stepper--"
		@acao_extra="pass"
		:btn_acao="stepper < 2 ? 'Próximo' : 'Alterar medicamento da box'"
		:btn_acao_disable="!isStepValid"
		@acao="pass"
		:loading="loading"
		@close="$emit('close', false);">

		<v-container fluid grid-list-lg class="pa-0">
			<v-stepper class="elevation-0" v-model="stepper">
				<v-stepper-header class="mb-3">
					<v-stepper-step :complete="stepper > 0" step="0" v-if="isSwapFromTag">
						Informação
					</v-stepper-step >
					<v-divider v-if="isSwapFromTag"/>
					<v-stepper-step :complete="stepper > 1" step="1">
						Alteração do medicamento
					</v-stepper-step>
					<v-divider />
					<v-stepper-step step="2">
						Confirmação
					</v-stepper-step>
				</v-stepper-header>
				<v-stepper-items>
					<v-stepper-content step="0" v-if="isSwapFromTag">
						<h2>Atenção!</h2> <br/>
						A etiqueta informada não corresponde ao produto <b>{{product.name}} - {{ product.presentation }}</b>. <br/>
						Produto referente a etiqueta: <b>{{tagInfo.product.name}} - {{tagInfo.product.presentation}}</b> <br/>
						Gostaria de realizar a alteração?

					</v-stepper-content>
					<v-stepper-content step="1">
						<div v-if="loading" class="carregando text-center">
							<v-progress-circular indeterminate color="primary" />
						</div>
						<div v-if="availableProducts.length > 0 && !loading">
							<v-text-field label="Buscar medicamento" placeholder="ID, Nome ou EAN"
								append-icon="search" color="purple" @input="value => filterMedicines(value)" />
							<v-radio-group v-model="selectedProductIdToSwap" class="ma-0 pb-3" hide-details v-if="filteredProducts.length > 0">
								<div
									class="outlined-radio mb-2"
									v-for="product in filteredProducts"
									:key="product.id">

									<v-radio class="py-3 px-3 custom-radio"
										:class="{ 'selected-radio': selectedProductIdToSwap === product.id }" :value="product.id" >
										<template v-slot:label>
											<div class="d-flex q-column" style="align-items: baseline">
												<div class="d-flex q-row align-center">
													<h4 class="text-uppercase">{{ product.name }}</h4>
													<!-- ICON PBM -->
													<v-tooltip top v-if="product.isPBM" >
														<v-chip class="justify-center" color="blue" style="max-width: 22px; max-height: 22px;" slot="activator" dark
															small><strong>%</strong></v-chip>
														<span>PBM</span>
													</v-tooltip>
												</div>
												<span>{{product.presentation}}</span>
												<span>{{product.description}}</span>
											</div>
										</template>
									</v-radio>
								</div>
							</v-radio-group>
							<div v-if="filteredProducts.length === 0">
								Nenhum produto encontrado para a busca.
							</div>
						</div>
						<div v-if="availableProducts.length === 0 && isSwapFromTag && !loading">
							O produto <b>{{tagInfo.product.name}} - {{tagInfo.product.presentation}}</b> (Etiqueta <b>{{tagInfo.tag}}</b>) não é similar ao  <b>{{product.name}} - {{ product.presentation }}</b> <br/>
							Insira a etiqueta correta ou faça a troca manual (sem inserir etiqueta na etapa anterior).
						</div>
						<div v-if="availableProducts.length === 0 && !isSwapFromTag && !loading">
							Nenhum medicamento similar encontrado para o produto <b>{{product.name}} - {{ product.presentation }}</b>. <br/>
							Prossiga com o medicamento atual ou exclua o medicamento da box.
						</div>
					</v-stepper-content>
					<v-stepper-content step="2">
						<h2>Atenção!</h2> <br/>
						Ao confirmar, o medicamento <b>{{product.name}} - {{ product.presentation }}</b> será alterado na box {{ boxId }} pelo medicamento
						<b>{{ selectedProductToSwap ? selectedProductToSwap.name : ''}} - {{ selectedProductToSwap ? selectedProductToSwap.presentation : ''}}.</b> <br/>

						<v-checkbox
							v-model="confirmedSwap"
							label="Declaro estar ciente desta alteração"
							hide-details
							class="text-bold"
							data-vv-name="Declaro estar ciente desta alteração"/>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</v-container>
	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { ProductPresenter } from '@Presenter/product-presenter';

export default {
	name: 'SwapMedicineDialog',
	props: {
		product: {
			type: Object,
			required: true
		},
		tagInfo: {
			type: Object,
			required: false
		},
		boxId: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		stockItems: [],
		items: [],
		loading: false,
		selectedProductIdToSwap: null,
		selectedProductToSwap: null,
		confirmedSwap: false,
		availableProducts: [],
		filteredProducts: [],
		stepper: 0
	}),
	async created() {
		if (!this.isSwapFromTag) {
			this.stepper = 1;
			await this.getProductsList();
		}
	},
	watch: {
		selectedProductIdToSwap() {
			this.selectedProductToSwap = this.availableProducts.find(i => i.id === this.selectedProductIdToSwap);
		}
	},
	computed: {
		isStepValid() {
			const isFirstStep = this.stepper < 1; // for some reason the step 0 is modified to a string "0"
			return isFirstStep || (this.stepper === 1 && this.selectedProductIdToSwap) || (this.stepper === 2 && this.confirmedSwap);
		},
		isSwapFromTag() {
			return !!this.tagInfo;
		}
	},
	methods: {
		async pass() {
			if (this.stepper < 1)
				await this.getProductsList();

			if (this.stepper === 2 && this.confirmedSwap && this.selectedProductIdToSwap)
				await this.changeBoxProduct();

			this.stepper++;
		},
		async changeBoxProduct() {
			this.loading = true;
			try {
				await BoxPresenter.changeBoxProductManualPicking({
					boxId: this.boxId,
					currentProduct: {
						productId: this.product.id
					},
					newProduct: {
						productId: this.selectedProductIdToSwap,
						tagId: this.tagInfo?.tag
					}
				});

				this.$emit('update', false);
			} catch (e) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'error',
						message: e.message || 'Erro ao alterar medicamento da box'
					}
				);
			} finally {
				this.loading = false;
				this.$emit('close', false);
			}
		},
		async getProductsList() {
			this.loading = true;
			try {
				const productsResponse = await ProductPresenter.getProductBySimilarity({ productId: this.product.id, compareQuantity: false });
				this.availableProducts = this.isSwapFromTag ? productsResponse.items.filter(i => i.id === this.tagInfo.product.id) : productsResponse.items;
				this.filteredProducts = this.availableProducts;
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: `Erro ao buscar medicamentos similares: ${e.message}` });
			} finally {
				this.loading = false;
			}
		},
		filterMedicines(value) {
			this.filteredProducts = value ? this.availableProducts.filter(p => p.name.toLowerCase().includes(value.toLowerCase())
				|| p.id.toString() === value
				|| p.gtins.find(gtin => gtin === value)) : this.availableProducts;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

  ::v-deep .v-input__control{
	flex-grow:1!important
  }

  ::v-deep .custom-radio  {
		border: 1px solid grey;
		border-radius: 10px;
  }

  ::v-deep .selected-radio  {
	border: 1px solid $primary;
	background-color: $primary_light;
  }

  ::v-deep .v-stepper__header {
	box-shadow: none;
	border-bottom: 3px solid #ffd9a0;
  }

</style>
