<template>
	<header class="mb-4">
		<v-layout row wrap align-content-space-between items-center>
			<v-flex xs12 md3 xl2>
				<h1 class="text--text" style="opacity: .8">
					Assinatura {{ $route.params.id }}
					<BoxExpress :small="false" :express="assinatura.express" />
					<FirstBox v-if="!assinatura.express && assinatura.primeira_box" />
				</h1>
				<p v-if="!assinatura.deleted_at" class="mb-0">Informações da assinatura.</p>
				<p v-else class="error--text text-bold mb-0">Esta assinatura está cancelada.</p>
			</v-flex>
			<v-flex xs12 md9 xl10>
				<ModalidadeAssinatura :modalidade="assinatura.modalidade" :assinaturaId="assinatura.id"
					:pacienteId="assinatura.paciente.id" />
			</v-flex>
		</v-layout>
		<v-layout wrap align-content-space-between>
			<v-flex v-if="showDataInicio" style="max-width: max-content">
				<TextDateField :value="assinatura.data_proxima_box" label="Data de início da próxima box"
					placeholder="dd/mm/yyyy" :box="true" color="green_strong" :validate="{ required: true, data: true }"
					:hideDetails="true" class="text-date-field" :flat="true" :disabled="disableNextDate" @input="onInput" />
			</v-flex>
			<v-flex align-self-center justify-end class="flex-end">
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-btn v-if="$store.getters.eh_administrador || $store.getters.eh_finaneceiro || $store.getters.eh_clinica"
							v-on="on" depressed class="green white--text">
							<v-icon small class="mr-2">attach_money</v-icon>
							Cobrança/cartão
						</v-btn>
					</template>
					<v-list>
						<v-list-tile v-if="$store.getters.eh_administrador || $store.getters.eh_finaneceiro"
							@click="dialog_iugu = true">
							<v-list-tile-title>Cobrança avulsa</v-list-tile-title>
						</v-list-tile>
						<v-list-tile @click="remover_metodos_pagamento = true">
							<v-list-tile-title>Descadastrar cartão</v-list-tile-title>
						</v-list-tile>
					</v-list>
				</v-menu>

				<v-menu v-if="!enableIntegrality" offset-y>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" depressed class="green white--text" >
							<v-icon small class="mr-2">print</v-icon>
							Imprimir
						</v-btn>
					</template>
					<v-list>
						<v-list-tile @click="imprimir">
							<v-list-tile-title>Assinatura</v-list-tile-title>
						</v-list-tile>
						<v-list-tile @click="imprimirTermoResponsabilidade">
							<v-list-tile-title>Termo / Relação</v-list-tile-title>
						</v-list-tile>
					</v-list>
				</v-menu>

				<v-btn v-if="$store.getters.user.permissao === $permissoes.Clinica || $store.getters.eh_administrador" depressed
					class="green white--text" @click="dialog_analise_prescricao = true">
					<v-icon small class="mr-2">fas fa-pills</v-icon>
					Análise de prescrição
				</v-btn>

				<v-btn depressed color="primary" :disabled="!assinatura_alterada" @click="dialog_salvar = true">
					<v-icon small class="mr-2">check</v-icon>
					Salvar alterações
				</v-btn>
			</v-flex>
		</v-layout>

		<v-dialog persistent v-model="dialog_iugu" scrollable transition="fade" max-width="920px">
			<transition>
				<DialogIugu v-if="dialog_iugu" :assinatura_id="assinatura.id" @close="dialog_iugu = false"
					:cobrancaAvulsa="true" />
			</transition>
		</v-dialog>

		<!-- Analise de prescricao dialog -->
		<v-dialog persistent v-model="dialog_analise_prescricao" scrollable transition="fade" max-width="920px">
			<transition>
				<DialogAnalisePrescricao v-if="dialog_analise_prescricao" :assinatura_id="assinatura.id"
					:close.sync="dialog_analise_prescricao" />
			</transition>
		</v-dialog>

		<!-- Remover formas pagamento -->
		<v-dialog persistent v-model="remover_metodos_pagamento" scrollable transition="fade" max-width="500px">
			<transition>
				<DialogDescadastrarPagamento v-if="remover_metodos_pagamento" :assinatura_iugu_id="assinatura.iugu_id"
					:close.sync="remover_metodos_pagamento" />
			</transition>
		</v-dialog>

		<!-- Dialog Salvar -->
		<v-dialog persistent scrollable v-model="dialog_salvar" transition="fade" max-width="600px">
			<transition>
				<DialogSalvar :assinatura="assinatura" :alterou_data_proxima_box="alterou_data_proxima_box"
					:alterou_medicamento="alterou_medicamento" @assinatura-salva="onAssinaturaSalva"
					@close="dialog_salvar = false" />
			</transition>
		</v-dialog>
	</header>
</template>

<script>
import DialogIugu from '@Componentes/dialog-iugu';
import BoxExpress from '@Componentes/box-express';
import FirstBox from '@Componentes/first-box';
import ModalidadeAssinatura from '@Componentes/assinatura/modalidade';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { openBuffer } from '@Util/functions';
import { featureFlags } from '@Consts/feature-flags';
import DialogSalvar from './dialog-salvar';
import DialogDescadastrarPagamento from './descadastrar-pagamentos';
import DialogAnalisePrescricao from './analise-prescricao/dialog-analise-prescricao';

export default {
	name: 'HeaderAssinatura',
	components: {
		DialogAnalisePrescricao, DialogSalvar, DialogIugu, DialogDescadastrarPagamento, BoxExpress, FirstBox, ModalidadeAssinatura
	},
	data: () => ({
		dialog_iugu: false,
		dialog_salvar: false,
		assinatura_alterada: false,
		alterou_medicamento: false,
		alterou_data_proxima_box: false,
		dialog_analise_prescricao: false,
		remover_metodos_pagamento: false,
		data_proxima_box: null,
		posicao_original: null
	}),
	computed: {
		assinatura() {
			return this.$store.state.assinatura.assinatura;
		},
		showDataInicio() {
			return this.$store.getters.eh_administrador || this.$store.getters.eh_rt;
		},
		disableNextDate() {
			const box = this.$store.getters['assinatura/lastBox'];
			return !!box.id && box.status !== 'DELIVERED';
		},
		enableIntegrality() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.integrality_b2c) && this.$store.getters.isFeatureFlagEnabled(featureFlags.integrality_b2b);
		}
	},
	mounted() {
		this.$root.$on('ALTEROU', this.alterouAssinatura);
		this.$root.$on('ALTEROU_MEDICAMENTO', this.alterouMedicamento);
		this.posicao_original = this.assinatura.posicao;
	},
	beforeDestroy() {
		this.$root.$off('ALTEROU', this.alterouAssinatura);
		this.$root.$off('ALTEROU_MEDICAMENTO', this.alterouMedicamento);
	},
	methods: {
		alterouAssinatura(bool) {
			this.assinatura_alterada = bool;
		},
		alterouMedicamento() {
			this.alterou_medicamento = true;
		},
		imprimir() {
			import('@Componentes/pdf-orcamento').then(pdf => {
				pdf.default(this.assinatura, true).getDataUrl(outDoc => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Orçamento gerado com sucesso!' });
					const a = document.createElement('a');
					a.href = outDoc;
					a.download = `Assinatura Farme - ${this.assinatura.paciente.nome}.pdf`;
					a.click();
				});
			});
		},
		async imprimirTermoResponsabilidade() {
			try {
				this.gerando_termo = true;
				const pdf = await AssinaturaPresenter.relacaoDeMedicamentos(
					this.assinatura.id,
					this.assinatura.paciente.nome,
					this.assinatura.paciente.cpf
				);
				openBuffer(pdf);
				return pdf;
			} catch (err) {
				throw new Error(err);
			} finally {
				this.gerando_termo = false;
			}
		},

		onAssinaturaSalva() {
			this.dialog_salvar = false;
			this.assinatura_alterada = false;
			this.alterou_medicamento = false;
			this.alterou_data_proxima_box = false;
			this.$emit('assinatura-salva');
			this.$root.$emit('ALTEROU', false);
		},
		onInput(data_proxima_box) {
			this.$root.$emit('ALTEROU', true);
			this.alterou_data_proxima_box = true;
			this.$store.commit('assinatura/SET_DATA_PROXIMA_BOX', data_proxima_box);
		},
		checkForChanges() {
			this.alterouAssinatura(this.posicao_original !== this.assinatura.posicao);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

@media screen and (min-width: $break-point-md) {
	.flex-end {
		display: flex;
		justify-content: flex-end;
	}
}

.text-date-field {
	font-weight: bold;
}
</style>
