<template>
	<Dialog
		titulo="Exclusão de medicamento"
		subtitulo="Ao realizar essa ação, o medicamento será excluído da box"
		:btn_acao="'Excluir medicamento da box'"
		:btn_acao_disable="!confirmedDelete"
		:loading="loading"
		@acao="deleteProduct"
		@close="$emit('close', false);">

		<v-container fluid grid-list-lg class="pa-0">
			<div v-if="loading" class="carregando text-center">
				<v-progress-circular indeterminate color="primary" />
			</div>
			<div v-else>
				<h2>Atenção!</h2> <br/>
				É possível fazer a alteração do medicamento ao invés de excluí-lo. <br/><br/>

				Ao confirmar, o medicamento <b>{{product.name}} - {{ product.presentation }}</b> será <b>excluído</b> da box {{ boxId }}. <br/>
				<b>Esta ação é irreversível!</b> <br/>

				<v-checkbox
					v-model="confirmedDelete"
					label="Declaro estar ciente que estou excluíndo o medicamento da box"
					hide-details
					class="text-bold"
					data-vv-name="Declaro estar ciente que estou excluíndo o medicamento da box"/>
			</div>
		</v-container>
	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';

export default {
	name: 'DeleteProductFromBoxDialog',
	props: {
		product: {
			type: Object,
			required: true
		},
		boxId: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		confirmedDelete: false,
		loading: false
	}),
	methods: {
		async deleteProduct() {
			if (!this.confirmedDelete)
				return this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Confirme a ação.' });

			this.loading = true;
			try {
				await BoxPresenter.deleteBoxProductManualPicking(this.boxId, this.product.id);
				this.$emit('update', false);
				this.$emit('close', false);
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: `Erro ao excluir produto da box ${e.message}` });
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
