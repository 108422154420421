<template>
	<v-container fluid grid-list-md class="pa-0">
		<v-layout wrap>
			<v-flex xs12>
				<RestHomeTypeBadge v-if="extra.businessType" :businessType="getBusinessType" class="mb-3"/>
				<CrossCompanyUnitBadge v-if="extra.orderProcessingUnitId && extra.orderProcessingUnitId !== extra.companyUnitId" class="mb-3"/>

				<p v-if="extra.restHome && !isB2C(extra.restHome.businessType)" class="font-weight-black title">{{ extra.restHome.tradingName }}</p>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { BUSINESS_B2C_TYPE } from '@Consts/business-type';
import { getAllCompanyUnits } from '@Consts/unidades';
import RestHomeTypeBadge from '@Views/componentes/rest-home-badge.vue';
import CrossCompanyUnitBadge from '@Views/componentes/quotation/cross-company-unit-badge.vue';

export default {
	$_veeValidate: { validator: 'new' },
	components: {
		RestHomeTypeBadge,
		CrossCompanyUnitBadge
	},
	props: {
		extra: {
			required: true,
			type: Object
		}
	},
	computed: {
		getBusinessType() {
			return this.extra.businessType;
		},
		units() {
			return getAllCompanyUnits().map(item => ({
				value: item.id,
				text: `${item.name} (${item.abbr})`
			}));
		}
	},
	methods: {
		isB2C(businessType) {
			return businessType === BUSINESS_B2C_TYPE.value;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
